import { EditingTabInfo } from "common/types/EditingTabInfo";
import { useState, ReactNode, createContext, Dispatch, SetStateAction } from "react";

type StatusContextType = {
  currentDate: Date;
  selectDate: (date: Date) => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadingMessage: string;
  setLoadingMessage: Dispatch<SetStateAction<string>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  toastTitle: string;
  toastMessage: string;
  setToastMessage: Dispatch<SetStateAction<string>>;
  showToastMessage: (title: string, msg: string) => void;
  isEditing: boolean; // Tab ID
  editingTab: EditingTabInfo | undefined;
  setEditingTab: (tabInfo: EditingTabInfo | undefined) => void;
};

const StatusContext = createContext({
  currentDate: new Date(),
  isLoading: false,
  loadingMessage: "",
  errorMessage: "",
  toastMessage: "",
} as StatusContextType);

export default StatusContext;

export const StatusContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [date, setDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingTab, setEditingTab] = useState<EditingTabInfo>();

  // ---------------------------------
  // 日付の選択処理
  // ---------------------------------
  const selectDateHandler = (date: Date) => {
    setDate(date);
    localStorage.setItem("currentDate", date.toISOString());
  };

  const showToastMessage = (title: string, msg: string) => {
    setToastTitle(title);
    setToastMessage(msg);
  };

  const setEditingTabHandler = (tabInfo: EditingTabInfo | undefined) => {
    setEditingTab(tabInfo);
    setIsEditing(tabInfo !== undefined);
  };

  const contextValue = {
    currentDate: date,
    selectDate: selectDateHandler,
    isLoading,
    setIsLoading,
    loadingMessage,
    setLoadingMessage,
    errorMessage,
    setErrorMessage,
    toastTitle,
    toastMessage,
    setToastMessage,
    showToastMessage,
    isEditing,
    setIsEditing,
    editingTab,
    setEditingTab: setEditingTabHandler,
  };

  return <StatusContext.Provider value={contextValue}>{children}</StatusContext.Provider>;
};
