import { ByMonth, DetailsByMonth, MonthComparison } from "../types/AggregateData";
import { CHART_TYPES } from "../types/ChartTypes";
import { NameID } from "common/types/NameID";
import { TAB_ID } from "common/types/consts/Defines";
import { getFiscalYear, toYearMonth } from "utils/DateTools";

export const useChartData = () => {
  const findTab = (tabs: NameID[], tabId: number) => {
    return tabs?.find((tab) => tab.id === tabId);
  };

  const getChartTitle = (chartType: CHART_TYPES, tabs?: NameID[]): string | undefined => {
    if (!tabs) return undefined;

    switch (chartType) {
      case CHART_TYPES.CHART_VISITORS_MONTHLY: {
        const tab = findTab(tabs, TAB_ID.VISITORS);
        if (!tab) return undefined;
        return `月別${tab.name}`;
      }
      case CHART_TYPES.CHART_VISITORS_ACCUMULATE: {
        const tab = findTab(tabs, TAB_ID.VISITORS);
        if (!tab) return undefined;
        return `累計${tab.name}`;
      }
      case CHART_TYPES.TABLE_VISITORS_TODAY: {
        const tab = findTab(tabs, TAB_ID.VISITORS);
        if (!tab) return undefined;
        return `本日の${tab.name}`;
      }
      case CHART_TYPES.TABLE_VISITORS_YESTERDAY: {
        const tab = findTab(tabs, TAB_ID.VISITORS);
        if (!tab) return undefined;
        return `昨日の${tab.name}`;
      }
      case CHART_TYPES.TABLE_VISITORS_MONTHLY: {
        const tab = findTab(tabs, TAB_ID.VISITORS);
        if (!tab) return undefined;
        return `月別詳細${tab.name}`;
      }
      case CHART_TYPES.CHART_INQUIRY_MONTHLY: {
        const tab = findTab(tabs, TAB_ID.INQUIRY);
        if (!tab) return undefined;
        return `月別${tab.name}件数`;
      }
      case CHART_TYPES.CHART_INQUIRY_ACCUMULATE: {
        const tab = findTab(tabs, TAB_ID.INQUIRY);
        if (!tab) return undefined;
        return `累計${tab.name}件数`;
      }
      case CHART_TYPES.TABLE_INQUIRY_TODAY: {
        const tab = findTab(tabs, TAB_ID.INQUIRY);
        if (!tab) return undefined;
        return `本日の${tab.name}`;
      }
      case CHART_TYPES.TABLE_INQUIRY_YESTERDAY: {
        const tab = findTab(tabs, TAB_ID.INQUIRY);
        if (!tab) return undefined;
        return `昨日の${tab.name}`;
      }
      default:
        return undefined;
    }
  };

  //--------------------------------------
  // 月別利用者情報を抽出する
  //--------------------------------------
  const extractVisitorsByMonth = (
    monthStart: number,
    data?: ByMonth[],
    cumulative: boolean = false
  ): MonthComparison[] => {
    // 月ごとのデータを格納する入れ物を作成
    const chartData = [...new Array(12).keys()].map<MonthComparison>((n) => {
      const month = (monthStart + n) % 12;
      return new MonthComparison(month === 0 ? 12 : month);
    });

    // 月ごとのデータを抽出
    const monthlyData = data?.map((x) => {
      return { year: x.year, month: x.month, data: x.visitorData?.places || [] };
    });

    const today = new Date();
    const thisYear = getFiscalYear(today, monthStart);
    const yearStarts = toYearMonth(thisYear, monthStart);

    // 月ごとのデータに振り分け
    monthlyData?.forEach((x) => {
      const val = x.data?.reduce((accum, curr) => accum + curr.value, 0);
      const index = (x.month - monthStart + 12) % 12;
      const target = chartData[index];
      toYearMonth(x.year, x.month) >= yearStarts
        ? (target.thisYear = val)
        : (target.prevYear = val);
    });

    const thisMonth = today.getMonth() + 1;
    const thisYearMonth = toYearMonth(today.getFullYear(), thisMonth); // 2023.11 => 202311

    // 累計？
    if (cumulative) {
      chartData.forEach((x, index) => {
        if (index > 0) {
          x.prevYear += chartData[index - 1].prevYear;

          // 今年の今日よりも前の月だけ累計を計算し、未来の月は常に0とする
          // 4月スタート、8月＝4+7 -> thisYear, 1月=4+8 -> 12
          const targetYear = monthStart + index < 12 ? thisYear : thisYear + 1;
          if (toYearMonth(targetYear, (monthStart + index) % 12) <= thisYearMonth) {
            x.thisYear += chartData[index - 1].thisYear;
          } else x.thisYear = 0;
        }
      });
    }
    return chartData;
  };

  //--------------------------------------
  // テーブル出力用に月別の利用者情報を抽出する
  //--------------------------------------
  const extractVisitorDetailsByMonth = (monthStart: number, data?: ByMonth[]): DetailsByMonth[] => {
    // 月ごとのデータを抽出
    const monthlyData = data?.map((x) => {
      return { year: x.year, month: x.month, data: x.visitorData?.places ?? [] };
    });

    const thisYear = getFiscalYear(new Date(), monthStart);

    // 月ごとのデータを格納する入れ物を作成
    const chartData = [...new Array(12).keys()].map<DetailsByMonth>((n) => {
      const month = (monthStart + n) % 12;
      // 2023年度の1月は2024年1月となるように
      const year = monthStart + n > 12 ? thisYear + 1 : thisYear;
      return {
        month: month === 0 ? 12 : month,
        prevYear: { year: year - 1, data: [] },
        thisYear: { year: year, data: [] },
      };
    });

    const yearStarts = toYearMonth(thisYear, monthStart);

    // 月ごとのデータに振り分け
    monthlyData?.forEach((x) => {
      const index = (x.month - monthStart + 12) % 12;
      const target = chartData[index];
      toYearMonth(x.year, x.month) >= yearStarts
        ? (target.thisYear.data = x.data)
        : (target.prevYear.data = x.data);
    });

    return chartData;
  };

  //--------------------------------------
  // 月別問合せ苦情要望情報を抽出する
  //--------------------------------------
  const extractInquiriesByMonth = (
    monthStart: number,
    data?: ByMonth[],
    cumulative: boolean = false
  ): MonthComparison[] => {
    // 月ごとのデータを格納する入れ物を作成
    const chartData = [...new Array(12).keys()].map<MonthComparison>((n) => {
      const month = (monthStart + n) % 12;
      return new MonthComparison(month === 0 ? 12 : month);
    });

    // 月ごとのデータを抽出
    const monthlyData = data?.map((x) => {
      return { year: x.year, month: x.month, data: x.inquiryData };
    });

    const today = new Date();
    const thisYear = getFiscalYear(today, monthStart);
    const yearStarts = toYearMonth(thisYear, monthStart);

    // 月ごとのデータに振り分け
    monthlyData?.forEach((x) => {
      const index = (x.month - monthStart + 12) % 12;
      const target = chartData[index];
      x.data?.forEach((y) => {
        if (y.id >= 0) return;
        toYearMonth(x.year, x.month) >= yearStarts
          ? (target.thisYear = y.total)
          : (target.prevYear = y.total);
      });
    });

    const thisMonth = new Date().getMonth() + 1;
    const thisYearMonth = toYearMonth(today.getFullYear(), thisMonth); // 2023.11 => 202311

    // 累計？
    if (cumulative) {
      chartData.forEach((x, index) => {
        // 前月との差分なので最初以外が対象
        if (index > 0) {
          x.prevYear += chartData[index - 1].prevYear;

          // 今年の今日よりも前の月だけ累計を計算し、未来の月は常に0とする
          // 4月スタート、8月＝4+7 -> thisYear, 1月=4+8 -> 12
          const targetYear = monthStart + index < 12 ? thisYear : thisYear + 1;
          if (toYearMonth(targetYear, (monthStart + index) % 12) <= thisYearMonth) {
            x.thisYear += chartData[index - 1].thisYear;
          } else x.thisYear = 0;
        }
      });
    }
    return chartData;
  };

  return {
    getChartTitle,
    extractVisitorsByMonth,
    extractVisitorDetailsByMonth,
    extractInquiriesByMonth,
  };
};
