import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./common/store/AuthContext";
import { StatusContextProvider } from "./common/store/StatusContext";
import { NotificationContextProvider } from "common/store/NotificationContext";
import { ThemeProvider } from "styled-components";
import { theme } from "theme/theme";

//import "./css/index.css";
import "./css/bootstrap.css";
import "./css/infoland.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <NotificationContextProvider>
        <AuthContextProvider>
          <StatusContextProvider>
            <App />
          </StatusContextProvider>
        </AuthContextProvider>
      </NotificationContextProvider>
    </ThemeProvider>
    ,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
