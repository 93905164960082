import React, { memo } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  value?: any;
  disabled?: boolean;
  size?: "sm" | "md" | "lg" | "none";
  tooltip?: string;
  buttonType?: "primary" | "edit" | "delete";
};

const FunctionButton = memo((props: Props) => {
  const { buttonType = "edit", size = "md" } = props;
  const getWidth = () => {
    switch (size) {
      case "sm":
        return "30px";
      case "md":
        return "50px";
      case "lg":
        return "80px";
      default:
        return "";
    }
  };
  return (
    <SButton
      style={{ width: getWidth() ?? "4rem" }}
      size="sm"
      className={`${props.className} ${buttonType} btn btn-info`}
      onClick={props.onClick}
      value={props.value}
      disabled={props.disabled ?? false}
      title={props.tooltip}
    >
      {props.children}
    </SButton>
  );
});

export default FunctionButton;

const SButton = styled(Button)`
  background-image: none;
  :disabled {
    border: none;
  }
  &.btn-primary:hover {
    background-image: none;
  }

  &.primary {
    background-color: ${(props) => props.theme.color.editButton};
    border-color: ${(props) => props.theme.color.editButtonBorder};
    :disabled {
      background-color: ${(props) => props.theme.color.editButtonDisabled};
    }
    &.btn-primary:hover {
      background-color: ${(props) => props.theme.color.editButtonHover};
    }
  }
  &.edit {
    background-color: ${(props) => props.theme.color.functionButton};
    border-color: ${(props) => props.theme.color.functionButtonBorder};
    :disabled {
      background-color: ${(props) => props.theme.color.functionButtonDisabled};
    }
    &.btn-primary:hover {
      background-color: ${(props) => props.theme.color.functionButtonHover};
    }
  }
  &.delete {
    background-color: ${(props) => props.theme.color.deleteButton};
    border-color: ${(props) => props.theme.color.deleteButtonBorder};
    :disabled {
      background-color: ${(props) => props.theme.color.deleteButtonDisabled};
    }
    &.btn-primary:hover {
      background-color: ${(props) => props.theme.color.deleteButtonHover};
    }
  }
`;
