import React from "react";
import FunctionButton from "../atoms/FunctionButton";

type Props = {
  editRequested: () => void;
  deleteRequested: () => void;
  editTooltip?: string;
  deleteTooltip?: string;
};

//=====================================
// テーブルデータの修正・削除ボタン
//=====================================
export const RowEditButtons = (props: Props) => {
  return (
    <>
      <FunctionButton
        tooltip={props.editTooltip ?? "編集"}
        className="ms-1 my-1"
        size="sm"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          props.editRequested();
        }}
      >
        <i className="fas fa-pen-to-square" />
      </FunctionButton>
      <FunctionButton
        buttonType="delete"
        tooltip={props.deleteTooltip ?? "削除"}
        className="ms-1"
        size="sm"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          props.deleteRequested();
        }}
      >
        <i className="fas fa-trash-alt" />
      </FunctionButton>
    </>
  );
};
