import React from "react";

export const DrawerNavigationHeader = (props: { className: string; children: React.ReactNode }) => {
  const { className, ...other } = props;
  const classNameActual = `nav-link ${className || ""}`.trim();

  return (
    <a {...other} className={classNameActual}>
      {props.children}
    </a>
  );
};
