import { memo } from "react";
import { Stack } from "react-bootstrap";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { getDateString, getTimeString } from "utils/commonTools";

type Props = {
  lastUpdated?: LastUpdatedInfo;
  className?: string;
};

const LastUpdatedCtrl = memo((props: Props) => {
  const { lastUpdated, className } = props;

  const updatedTimeString = (updated: Date) => {
    // 時間部分は共通
    const timePart = getTimeString(updated);

    // 日付部分は直近であれば今日や一昨日と入れたい
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 今日の0:00分との比較
    const timeDiff = updated.getTime() - today.getTime();
    const aDay = 60 * 60 * 24 * 1000; // 1日のミリ秒
    if (timeDiff > 0 && timeDiff < aDay) {
      return `本日 ${timePart}`;
    } else if (timeDiff > (-aDay)) {
      return `昨日 ${timePart}`;
    } else {
      return `${getDateString(updated)} ${timePart}`;
    }
  };

  return (
    <div className={`${className}`}>
      {!lastUpdated ? (
        <label>新規登録</label>
      ) : (
        <>
          <Stack direction="horizontal">
            <label className="me-2">最終更新:</label>
            {lastUpdated.savedByApp && <label className="me-2"> (アプリ) </label>}
            <label>{`${updatedTimeString(new Date(lastUpdated.date))} by ${
              lastUpdated.name
            }`}</label>
          </Stack>
        </>
      )}
    </div>
  );
});

export default LastUpdatedCtrl;
