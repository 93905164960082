import { Badge } from "react-bootstrap";
import { getHolidayName } from "utils/commonTools";

export const HolidayBadge = (props: { date: Date }) => {
  const holidayName = getHolidayName(props.date);
  return holidayName ? (
    <Badge pill bg="danger" className="ms-2">
      {holidayName}
    </Badge>
  ) : (
    <></>
  );
};
