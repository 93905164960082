import { useContext } from "react";
import axios from "axios";
import AuthContext from "common/store/AuthContext";

import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { toJapaneseDate } from "utils/DateTools";
import { PeriodicalEvent, PeriodicalEventRecord } from "../types/PeriodicalEvent";
import { GetBasicResponse, PostResponse } from "common/types/responses/BasicResponse";

export const usePeriodList = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  /**
   * Get list of events
   * @param parkId
   * @param from
   * @param to
   * @returns
   */
  const getPeriodEvents = async (
    parkId: number,
    from?: Date,
    to?: Date
  ): Promise<{ succeeded: boolean; msg: string; data?: PeriodicalEvent[] }> => {
    try {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      const queryParam = `${from && "startDate=" + toJapaneseDate(from)}${from && to && "&"}${
        to && "endDate=" + toJapaneseDate(to)
      }`;

      const { data } = await axios.get(
        getServerUrl() + `periodicalEvents/${parkId}/list?${queryParam}`,
        config
      );

      const result = data as GetBasicResponse<PeriodicalEvent[]>;
      if (!result) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      authCtxt.updateToken(result.token, new Date(result.expiresAt));

      return {
        succeeded: true,
        msg: "",
        data: result.data.map((x) => {
          return {
            ...x,
            startDate: x.startDate ? new Date(x.startDate + "Z") : undefined,
            endDate: x.endDate ? new Date(x.endDate + "Z") : undefined,
          } as PeriodicalEvent;
        }),
      };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  const createEvent = async (
    parkId: number,
    eventData: PeriodicalEvent
  ): Promise<{ succeeded: boolean; msg: string; data?: PeriodicalEvent }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };
    try {
      const url = getServerUrl() + `periodicalEvents/${parkId}`;
      const { data } = await axios.post(url, eventData, config);
      const response = data as GetBasicResponse<PeriodicalEvent>;
      if (!response) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }
      authCtxt.updateToken(response.token, new Date(response.expiresAt));
      return { succeeded: true, msg: "", data: { ...eventData, eventId: response.data.eventId } };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  const updateEvent = async (
    parkId: number,
    eventData: PeriodicalEvent
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };
    try {
      const url = getServerUrl() + `periodicalEvents/${parkId}`;
      const { data } = await axios.put(url, eventData, config);
      const response = data as PostResponse;
      if (!response) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }
      authCtxt.updateToken(response.token, new Date(response.expiresAt));

      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  /**
   * Delete an event
   * @param parkId
   * @param eventId
   * @returns
   */
  const deleteEvent = async (
    parkId: number,
    eventId: number
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };

    try {
      const { data } = await axios.delete(
        getServerUrl() + `periodicalEvents/${parkId}/${eventId}`,
        config
      );
      const responseData = data as PostResponse;
      if (!responseData) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  /**
   * Get event data from eventId
   * @param parkId
   * @param eventId
   * @returns
   */
  const getEventData = async (
    parkId: number,
    eventId: number
  ): Promise<{ succeeded: boolean; msg: string; data?: PeriodicalEventRecord }> => {
    try {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      const { data } = await axios.get(
        getServerUrl() + `periodicalEvents/${parkId}/${eventId}`,
        config
      );

      const result = data as GetBasicResponse<PeriodicalEventRecord>;
      if (!result) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      authCtxt.updateToken(result.token, new Date(result.expiresAt));

      return {
        succeeded: true,
        msg: "",
        data: !result.data
          ? undefined
          : {
              summary: result.data.summary,
              details: result.data.details.map((x) => {
                return {
                  ...x,
                  date: new Date(x.date),
                };
              }),
            },
      };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  const updateEventData = async (
    parkId: number,
    eventData: PeriodicalEvent
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };
    try {
      const url = getServerUrl() + `periodicalEvents/${parkId}/${eventData.eventId}`;
      const { data } = await axios.post(url, eventData.record, config);
      const response = data as PostResponse;
      if (!response) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }
      authCtxt.updateToken(response.token, new Date(response.expiresAt));

      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  return { getPeriodEvents, createEvent, updateEvent, getEventData, deleteEvent, updateEventData };
};
