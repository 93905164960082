import { NameID } from "common/types/NameID";
import { ExclusiveUsesData, ExclusiveUsesSummary } from "../types/ExclusiveUsesReport";

export const useExclusiveUsesData = () => {
  const TOTAL = -1;

  const createEmptySummaries = (usageTypes: NameID[]): ExclusiveUsesSummary[] => {
    const usagesWithTotal: NameID[] = [...usageTypes, { id: TOTAL, name: "合計" }];
    const emptySummaries: ExclusiveUsesSummary[] = usagesWithTotal.map((x) => ({
      usageType: x,
      count: 0,
      sales: 0,
    }));
    return emptySummaries;
  };

  const createEmptyData = (): ExclusiveUsesData => {
    return {
      occupantName: "",
      contact: "",
      contents: "",
      sales: 0,
      exemption: false,
    };
  };

  //--------------------------------------
  // サマリーの再計算を行う
  //--------------------------------------
  const updateSummaries = (summaries: ExclusiveUsesSummary[], details: ExclusiveUsesData[]) => {
    var total = summaries.find((x) => x.usageType.id === TOTAL)!;
    total.count = 0;
    total.sales = 0;

    summaries.forEach((cat) => {
      if (cat.usageType.id === TOTAL) return;
      const targets = details.filter((x) => x.usageType?.id === cat.usageType.id);
      cat.count = targets.length;
      total.count += targets.length;
      cat.sales = targets.reduce((prev, curr) => prev + curr.sales, 0);
      total.sales += cat.sales;
    });

    return [...summaries];
  };

  return { createEmptySummaries, createEmptyData, updateSummaries };
};
