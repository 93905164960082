import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled, { CSSProperties } from "styled-components";

type Params = {
  className?: string;
  id?: string;
  value: string;
  onChange: (value: string) => void;
  readOnly: boolean;
  borderAlways?: boolean;
  style?: CSSProperties;
};
const MyTextArea = memo((params: Params) => {
  const { className, id, value, onChange, readOnly = false, borderAlways = true, style } = params;
  const [text, setText] = useState("");

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <SFormText
      as="textarea"
      className={`form-control ${className ?? ""} ${borderAlways && "borderAlways"}`}
      style={style}
      id={id}
      value={text}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setText(e.target.value);
        onChange(e.target.value);
      }}
      readOnly={readOnly}
    />
  );
});

export default MyTextArea;

const SFormText = styled(Form.Control)`
  height: 100px;
  line-height: 1.2;
  border-width: 2px;
  :read-only {
    border-width: 1px;
    box-shadow: none;
    :not(.borderAlways) {
      border: none;
    }
    background-color: transparent;
  }
`;
