//--------------------------------------
// 日付の計算処理を共通化

import dayjs from "dayjs";

//--------------------------------------
export const addDays = (date: Date, numDays: number) => {
  return dayjs(date).add(numDays, "days").toDate();
};

//--------------------------------------
export const addMonths = (date: Date, numMonths: number) => {
  return dayjs(date).add(numMonths, "months").toDate();
};

//--------------------------------------
// 会計年度を計算する
//--------------------------------------
export const getFiscalYear = (date: Date, startMonth: number) => {
  return date.getMonth() + 1 >= startMonth ? date.getFullYear() : date.getFullYear() - 1;
};

//--------------------------------------
// 月と年を合わせた数字にする (前後を計算するため)
// 例. 2024年1月 -> 202401
//--------------------------------------
export const toYearMonth = (year: number, month: number) => year * 100 + month;

//--------------------------------------
// 月の日数を返す
//--------------------------------------
export const daysInMonth = (date: Date) => {
  return dayjs(date).daysInMonth();
};

//--------------------------------------
// クエリー生成時に日本時間の文字列に修正する
//--------------------------------------
export const toJapaneseDate = (date: Date) => {
  return date.toLocaleDateString("ja");
};

//--------------------------------------
// 年度の開始と終了日を取得する
//--------------------------------------
export const getFiscalYearDates = (year: number, startMonth: number, startDay: number) => {
  const yearFrom = new Date(year, startMonth - 1, startDay);
  const yearEnd = new Date(yearFrom);
  yearEnd.setFullYear(yearFrom.getFullYear() + 1);
  const yearTo = addDays(yearEnd, -1);
  return { yearFrom, yearTo };
};

export const getFormattedDate = (date: Date, format: string) => {
  const symbol = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  };

  const formatted = format.replace(/(M+|d+|h+|m+|s+)/g, (v) =>
    ((v.length > 1 ? "0" : "") + symbol[v.slice(-1) as keyof typeof symbol]).slice(-2)
  );

  return formatted.replace(/(y+)/g, (v) => date.getFullYear().toString().slice(-v.length));
};

export const getDayOfWeek = (date: Date) => {
  switch (date.getDay()) {
    case 0:
      return "日";
    case 1:
      return "月";
    case 2:
      return "火";
    case 3:
      return "水";
    case 4:
      return "木";
    case 5:
      return "金";
    case 6:
      return "土";
  }
};

export const toFormattedDate = (dt?: Date) => {
  if (!dt) return "";

  return `${getFormattedDate(dt, "yyyy/M/d")} (${getDayOfWeek(dt)})`;
};
