import { Table } from "react-bootstrap";
import styled from "styled-components";

import MyCheckbox from "common/components/atoms/MyCheckbox";
import MyTextbox from "common/components/atoms/MyTextbox";

import { InActiveStyle } from "common/types/styles/InActiveStyle";

import { StaffAttendance } from "../types/StaffInfo";

type Props = {
  data: Array<StaffAttendance>;
  dataChanged: (newVal: StaffAttendance[]) => void;
  readOnly?: boolean;
};

export const ProperStaffsTable = (props: Props) => {
  const { data, dataChanged, readOnly = false } = props;

  return (
    <>
      <h4>常勤者 出勤</h4>
      {!data ? (
        <p>データがありません</p>
      ) : (
        <Table bordered hover responsive>
          <thead>
            <tr className="text-center">
              <th className="col-md-*">名前</th>
              <th className="col-md-*">役職</th>
              <th className="col-md-1">予定</th>
              <th className="col-md-1">実績</th>
              <th className="col-md-*">備考</th>
            </tr>
          </thead>
          <tbody>
            {data.map((person) => {
              return (
                <tr
                  key={`employee_${person.employeeID}`}
                  style={person.retired ? InActiveStyle : undefined}
                >
                  <TdCenter> {person.employeeName} </TdCenter>
                  <TdCenter> {person.postName} </TdCenter>
                  <TdCenter>
                    <MyCheckbox
                      className=""
                      initialValue={person.expected}
                      changed={(checked) => {
                        person.expected = checked;
                        dataChanged(data);
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                  <TdCenter>
                    <MyCheckbox
                      className=""
                      initialValue={person.attended}
                      changed={(checked) => {
                        person.attended = checked;
                        dataChanged(data);
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                  <TdCenter>
                    <MyTextbox
                      className=""
                      initialValue={person.memo}
                      changed={(text) => {
                        person.memo = text;
                        dataChanged(data);
                      }}
                      readonly={readOnly}
                    />
                  </TdCenter>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;
