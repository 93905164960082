import { memo } from "react";
import { Toast } from "react-bootstrap";

type Props = {
  title: string;
  message: string;
  variant: string;
  duration: number;
  onClose: () => {}
};

const MyToast = memo(({ title, message, variant, duration, onClose }: Props) => {
  return (
    <Toast bg={variant} onClose={onClose} delay={duration} autohide>
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>
      {message}
      </Toast.Body>
    </Toast>
  );
});

export default MyToast;
