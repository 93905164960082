import { useState, useEffect, useContext } from "react";

import { PageTop } from "common/components/organisms/PageTop";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { BaseForm } from "common/components/templates/BaseForm";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { createDocumentTitle } from "utils/commonTools";

import { InquirySummaryTable } from "./components/InquirySummaryTable";
import { InquiryDetailTable } from "./components/InquiryDetailTable";
import { EditInquiryDlg, TargetInquiry } from "./components/EditInquiryDlg";

import { InquiryData, InquirySummary } from "./types/InquiryInfo";
import { InquiryMeta } from "./types/InquiryMeta";

import { useInquiry } from "./hooks/useInquiry";
import { useInquiryData } from "./hooks/useInquiryData";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

let isInitializing = false;

//=====================================
// 問合せ苦情要望ページ
//=====================================
const InquiryPage = (props: { pageTitle: string }) => {
  const pageTitle = props.pageTitle;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const [metaData, setMetaData] = useState<InquiryMeta | null>(null);
  const [summaries, setSummaries] = useState<InquirySummary[] | null>(null);
  const [details, setDetails] = useState<InquiryData[]>([]);

  const { loadMetaData, getReportData, saveReportData } = useInquiry();
  const { createEmptyInquiryData, createEmptySummaries, updateSummaries } = useInquiryData();

  const [editTarget, setEditTarget] = useState<TargetInquiry | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<InquiryData | null>(null);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<InquiryMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: InquiryMeta) => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );
    if (!baseData) setSummaries(null);

    const summaries = createEmptySummaries(baseData.categories);
    if (data?.details) {
      setSummaries(updateSummaries(summaries, data.details));
    } else {
      setSummaries(summaries);
    }
    setDetails(data?.details ?? []);
  };

  //--------------------------------------
  // データの追加
  //--------------------------------------
  const addDataRequested = () => {
    const newData = createEmptyInquiryData();
    if (selectedPark?.prefix) {
      // 整理番号
      const no = (details?.length ?? 0) + 1;
      newData.inquiryNo = `${selectedPark.prefix}${("000" + no).slice(-3)}`;
    }
    setEditTarget({ index: -1, data: newData });
  };

  //--------------------------------------
  // データの編集
  //--------------------------------------
  const editDataRequested = (data: InquiryData, index: number) => {
    setEditTarget({ index, data });
  };

  //--------------------------------------
  // データの追加・編集の完了
  //--------------------------------------
  const addOrEditCompleted = (data: InquiryData) => {
    if (!editTarget) return;

    let newArray = details;
    if (!newArray) newArray = [];

    if (editTarget.index < 0) {
      // 追加
      newArray?.push(data);
      setDetails(newArray);
    } else {
      // 編集
      newArray![editTarget.index] = data;
      setDetails(newArray);
    }

    setSummaries(updateSummaries(summaries!, newArray));

    setEditTarget(null);
  };

  //--------------------------------------
  // 削除の実行
  //--------------------------------------
  const confirmDeleteOK = async () => {
    var newList = details!.filter((x) => x !== deleteTarget);
    setDetails(newList);
    setSummaries(updateSummaries(summaries!, newList));
    setDeleteTarget(null);
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !summaries) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      currentDate,
      {
        summaries,
        details,
      },
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadData = async () => {
    if (metaData) await loadReportData(metaData);
  };

  return (
    <DashboardTemplate>
      {!selectedPark && <div> 公園が選択されていません </div>}
      <PageTop
        title={pageTitle}
        reportType={REPORT_TYPE.DAILY}
        tabId={TAB_ID.INQUIRY}
        lastUpdated={lastUpdated}
        onEditStart={async () => await reloadData()}
        onSave={!readOnly ? onSave : undefined}
        onCancel={async () => await reloadData()}
      />
      <BaseForm className="p-3">
        <InquirySummaryTable Summaries={summaries} />
        <InquiryDetailTable
          Details={details}
          readonly={!isEditing}
          addDataRequested={addDataRequested}
          editDataRequested={editDataRequested}
          deleteDataRequested={(data) => setDeleteTarget(data)}
        />
      </BaseForm>
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={["選択したデータを削除しても良いですか"]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
      {metaData && (
        <EditInquiryDlg
          target={editTarget}
          metaData={metaData}
          onOK={addOrEditCompleted}
          onCancel={() => setEditTarget(null)}
        />
      )}
    </DashboardTemplate>
  );
};

export default InquiryPage;
