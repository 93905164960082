import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { useContext, useEffect, useState } from "react";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import FormContainer from "common/components/templates/FormContainer";

import { PeriodEventList } from "./components/PeriodEventList";
import { PeriodicalEvent } from "./types/PeriodicalEvent";
import { ParkEventMeta } from "pages/parkevents/types/ParkEventMeta";

import { useParkEvents } from "../parkevents/hooks/useParkEvents";
import { EVENT_TYPE } from "pages/parkevents/types/EVENT_TYPE";
import { createDocumentTitle } from "utils/commonTools";
import { PeriodEventDetails } from "./components/PeriodEventDetails";

let isInitializing = false;

export const PeriodEventPage = (props: { pageTitle: string }) => {
  const { pageTitle } = props;
  const { selectedPark } = useContext(AuthContext);

  // 参加書の定義はイベント（自主・協働事業）のものを使用する
  const { loadMetaData } = useParkEvents();
  const [metaData, setMetaData] = useState<ParkEventMeta | null>(null);

  const [selectedEvent, setSelectedEvent] = useState<PeriodicalEvent>();

  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<ParkEventMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(
      selectedPark.parkId,
      EVENT_TYPE.Independent
    );

    setIsLoading(false);

    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedPark === undefined) return <div>公園が選択されていません</div>;

  return (
    <DashboardTemplate>
      <FormContainer size="max">
        {!selectedEvent ? (
          <PeriodEventList title={pageTitle} showDetailRequested={setSelectedEvent} />
        ) : (
          <PeriodEventDetails
            title={pageTitle}
            periodEvent={selectedEvent}
            participants={metaData?.participantTypes}
            backRequested={() => setSelectedEvent(undefined)}
          />
        )}
      </FormContainer>
    </DashboardTemplate>
  );
};
