/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";

import {
  generateErrorMsg,
  genApiDatePath,
  getServerUrl,
  generateSaveResult,
} from "utils/commonTools";
import {
  GetBasicResponse,
  PostBasicResponse,
  PostResponse,
} from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { StaffAttendanceReport } from "../types/StaffInfo";
import { useStaffsData } from "./useStaffsData";
import { Staffs } from "common/types/Staffs";
import { REPORT_TYPE } from "common/types/consts/Defines";

//======================================
// 従業員一覧関連の処理
//======================================
export const useStaffs = () => {
  const { createStaffData } = useStaffsData();

  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 従業員一覧情報をサーバーから取得する
  //--------------------------------------
  const loadMetaData = useCallback(
    async (
      parkId: number
    ): Promise<{ succeeded: boolean; msg: string; data?: StaffAttendanceReport }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `parkstaffs/${parkId}`, config);
        const metaData = data as GetBasicResponse<Staffs>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        const initialValue = {
          fulltime: metaData.data.fulltime?.map((staff) => createStaffData(staff)),
          parttime: metaData.data.parttime?.map((staff) => createStaffData(staff)),
          tempstaff: metaData.data.tempstaff?.map((staff) => createStaffData(staff)),
          others: "",
          numothers: 0,
        } as StaffAttendanceReport;
        return { succeeded: true, msg: "", data: initialValue };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      date: Date
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: StaffAttendanceReport;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url = getServerUrl() + `parkstaffs/report/${parkId}/${reportType}/${genApiDatePath(date)}`;

        const { data } = await axios.get(url, config);
        const reportData = data as PostBasicResponse<StaffAttendanceReport>;
        if (!reportData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(reportData.token, new Date(reportData.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: reportData.data,
          lastUpdated: reportData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const saveReportData = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      date: Date,
      reportData: StaffAttendanceReport,
      title: string,
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url = getServerUrl() + `parkstaffs/report/${parkId}/${reportType}/${genApiDatePath(date)}`;
        const { data, status } = await axios.post(url, reportData, config);

        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult(title, status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  return {
    loadMetaData,
    getReportData,
    saveReportData,
  };
};
