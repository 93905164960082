import React from "react";

export const DrawerNavigationItem = (props: {
  className: string;
  children: React.ReactNode;
  href: string;
}) => {
  const { href } = props;

  return (
    <div className="nav-item">
      <a className="nav-link" href={href}>
        {props.children}
      </a>
    </div>
  );
};
