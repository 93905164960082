import { useState } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";

import PrimaryButton from "../atoms/PrimaryButton";
import { CancelButton } from "../atoms/CancelButton";

type Props = {
  show: boolean;
  title: string;
  message: string;
  initialValue?: string;
  onOk: (title: string) => void;
  onCancel: () => void;
};

export const InputTextDlg = (props: Props) => {
  const { title, initialValue = "", message = "", show, onOk, onCancel } = props;

  const [text, setText] = useState(initialValue);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FloatingLabel className="mb-2 my-2 " label="タイトル">
              <Form.Control
                type="text"
                placeholder={message}
                value={text}
                onChange={(e) => setText(e.target.value)}
                autoComplete="off"
              />
            </FloatingLabel>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          className="mx-2"
          disabled={!text}
          onClick={() => {
            onOk(text);
          }}
          title="決定"
        />
        <CancelButton onClick={() => onCancel()} />
      </Modal.Footer>
    </Modal>
  );
};
