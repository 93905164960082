import { TAB_ID } from "common/types/consts/Defines";

export enum CHART_TYPES {
  CHART_VISITORS_MONTHLY = TAB_ID.VISITORS * 10,
  CHART_VISITORS_ACCUMULATE,
  TABLE_VISITORS_TODAY,
  TABLE_VISITORS_YESTERDAY,
  TABLE_VISITORS_MONTHLY,
  CHART_INQUIRY_MONTHLY = TAB_ID.INQUIRY * 10,
  CHART_INQUIRY_ACCUMULATE,
  TABLE_INQUIRY_TODAY,
  TABLE_INQUIRY_YESTERDAY
}

export type ChartTypeInfo = {
  id: number,
  name: string,
  tabId: TAB_ID,
}

export const ChartTypes: ChartTypeInfo[] = [
  { id: CHART_TYPES.CHART_VISITORS_MONTHLY, name: "月別利用者数", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.CHART_VISITORS_ACCUMULATE, name: "累計利用者数", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.TABLE_VISITORS_TODAY, name: "本日の利用者（表）", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.TABLE_VISITORS_YESTERDAY, name: "昨日の利用者", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.TABLE_VISITORS_MONTHLY, name: "月別利用者数", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.CHART_INQUIRY_MONTHLY, name: "月別問合苦情要望 件数", tabId: TAB_ID.INQUIRY },
  { id: CHART_TYPES.CHART_INQUIRY_ACCUMULATE, name: "累計問合苦情要望 件数", tabId: TAB_ID.INQUIRY },
  { id: CHART_TYPES.TABLE_INQUIRY_TODAY, name: "本日の問合苦情要望", tabId: TAB_ID.VISITORS },
  { id: CHART_TYPES.TABLE_INQUIRY_YESTERDAY, name: "昨日の問合苦情要望", tabId: TAB_ID.VISITORS },
];
