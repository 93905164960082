import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Tab, Tabs } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { BaseForm } from "common/components/templates/BaseForm";
import FunctionButton from "common/components/atoms/FunctionButton";
import { EditControl } from "common/components/organisms/EditControl";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

import { PeriodicalData, PeriodicalEvent, PeriodicalEventRecord } from "../types/PeriodicalEvent";

import { ParkEventPanel } from "pages/parkevents/components/ParkEventPanel";
import { usePeriodList } from "../hooks/usePeriodList";
import { usePeriodData } from "../hooks/usePeriodData";
import { useFileUtils } from "common/hooks/useFileUtils";
import { usePeriodicalEventReport } from "pages/print/hooks/usePeriodicalEventReport";
import { NameID } from "common/types/NameID";
import { toFormattedDate } from "utils/DateTools";
import { MyDatePicker } from "common/components/atoms/MyDatePicker";
import { PeriodEventSummary } from "./PeriodEventSummary";

type Props = {
  title: string;
  periodEvent: PeriodicalEvent;
  participants?: NameID[];
  backRequested: () => void;
};

export const PeriodEventDetails = (props: Props) => {
  const { title, periodEvent, participants, backRequested } = props;

  const { selectedPark } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage, isEditing, setErrorMessage, showToastMessage } =
    useContext(StatusContext);
  const [record, setRecord] = useState<PeriodicalEventRecord>();
  const [deletedEvents, setDeletedEvents] = useState<PeriodicalData[]>([]);
  const [activeTab, setActiveTab] = useState<string>("summary");

  const { updateEventData, getEventData } = usePeriodList();
  const { createEmptyRecord, createEmptyDetail } = usePeriodData();
  const { execFileDelete, execFileUpload } = useFileUtils();
  const { getPeriodiicalEventReport } = usePeriodicalEventReport();

  const addEventRequested = () => {
    if (!record) return;
    const newData = createEmptyDetail(participants);
    setRecord({ ...record, details: [...record?.details, newData] });
    setActiveTab(newData.recordId);
  };

  const updateParticipants = () => {
    if (!record) return;

    const summary = record.summary;
    summary.participants.forEach((target) => {
      target.count =
        record.details.reduce((prev, curr) => {
          return (
            prev + (curr.participants.find((x) => x.nameID.id === target.nameID.id)?.count ?? 0)
          );
        }, 0) ?? 0;
    });
    setRecord({ ...record });
  };

  const updateRevenue = () => {
    if (!record) return;

    const revReport = record.summary.revenueReport;
    if (!revReport) return;

    revReport.fee = record.details.reduce((prev, curr) => prev + (curr.revenueReport?.fee ?? 0), 0);
    revReport.numPaid = record.details.reduce(
      (prev, curr) => prev + (curr.revenueReport?.numPaid ?? 0),
      0
    );
    revReport.honorarium = record.details.reduce(
      (prev, curr) => prev + (curr.revenueReport?.honorarium ?? 0),
      0
    );
    revReport.materialCosts = record.details.reduce(
      (prev, curr) => prev + (curr.revenueReport?.materialCosts ?? 0),
      0
    );
    revReport.settlementAmount = record.details.reduce(
      (prev, curr) => prev + (curr.revenueReport?.settlementAmount ?? 0),
      0
    );
    setRecord({ ...record });
  };

  const processPictures = async (parkEvent: PeriodicalData) => {
    await Promise.all(
      parkEvent.pictures.map(async (theFile) => {
        if (theFile.deleted && theFile.fileId >= 0) {
          await execFileDelete(selectedPark!.parkId, theFile);
        } else if (theFile.file) {
          await execFileUpload(selectedPark!.parkId, theFile);
        }
      })
    );
  };

  const getDateString = (date: Date) => {
    return `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}${String(
      date.getDate()
    ).padStart(2, "0")}`;
  };

  const printDetail = async (data: PeriodicalData) => {
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage(`"${periodEvent.title}"の帳票を作成中...`);

    const { succeeded, msg } = await getPeriodiicalEventReport(
      periodEvent.eventId,
      periodEvent.title,
      getDateString(data.date),
      data.recordId
    );

    setIsLoading(false);
    if (!succeeded) setErrorMessage(msg);
  };

  const printSummary = async () => {
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage(`"${periodEvent.title}"の帳票を作成中...`);

    const { succeeded, msg } = await getPeriodiicalEventReport(
      periodEvent.eventId,
      periodEvent.title,
      "summary"
    );

    setIsLoading(false);
    if (!succeeded) setErrorMessage(msg);
  };

  const updateReportsTitles = () => {
    if (!record) return;
    record.summary.title = periodEvent.title;
    record.details.forEach((x) => (x.title = periodEvent.title));
    return record;
  };

  const onSave = async () => {
    if (!selectedPark) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage(`${periodEvent.title}の更新中`);

    // イベントごと削除した場合、登録済みのファイルを消す必要がある
    deletedEvents.forEach((ev) => {
      ev.pictures.forEach((pic) => (pic.deleted = true));
    });
    await Promise.all(deletedEvents.map(async (ev) => await processPictures(ev)));

    // イベントに対して新しく追加・削除したファイルはここでサーバーに登録する
    if (record) {
      await Promise.all(record.details.map(async (ev) => await processPictures(ev)));

      record.details.forEach((ev) => (ev.pictures = ev.pictures.filter((x) => !x.deleted)));

      // 日付でソートする
      record.details.sort((x, y) => (x.date > y.date ? 1 : -1));
    }

    // 個々の詳細のタイトルが未設定なので更新（← 印刷時に使用する）
    const updatedRecord = updateReportsTitles();

    periodEvent.record = updatedRecord;
    const { succeeded, msg } = await updateEventData(selectedPark.parkId, periodEvent);

    setIsLoading(false);
    if (succeeded) {
      showToastMessage(`${periodEvent.title}の保存`, "登録に成功しました");
    } else {
      setErrorMessage(msg);
    }
  };

  const loadData = useCallback(async () => {
    if (!selectedPark) return;

    const { data } = await getEventData(selectedPark.parkId, periodEvent.eventId);
    if (data) setRecord(data);
    else setRecord(createEmptyRecord(participants));

    // // タブが何も選択されていない状態を無くす
    // const currentTab = record?.details.find((x) => x.recordId === activeTab);
    // if (!currentTab) setActiveTab("summary");

    setDeletedEvents([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodEvent]);

  if (!selectedPark) return <div>公園が選択されていません</div>;
  if (!record) return <div>データがセットされていません</div>;

  return (
    <>
      <STopDiv className="my-3 mx-2">
        {/* タイトル */}
        <div className="h4">
          {periodEvent.title} ({title})
        </div>
        <SRightDiv>
          {/* 戻るボタン */}
          {!isEditing && (
            <FunctionButton onClick={() => backRequested()} tooltip="一覧に戻る" size="none">
              一覧に戻る
            </FunctionButton>
          )}
          <EditControl
            parkId={selectedPark.parkId}
            editKey={periodEvent.eventId.toString()}
            reportType={REPORT_TYPE.OTHERS}
            tabId={TAB_ID.PERIODICAL_EVENT}
            onEditStart={() => loadData()}
            onSave={onSave}
            onCancel={() => loadData()}
          />
        </SRightDiv>
      </STopDiv>
      <BaseForm>
        <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e ?? "summary")}>
          {/* 全体タブ */}
          <Tab title="サマリー" eventKey="summary">
            <PeriodEventSummary
              from={periodEvent.startDate}
              to={periodEvent.endDate}
              eventType={periodEvent.eventType}
              printRequested={printSummary}
              record={record}
              readonly={!isEditing}
            />
          </Tab>

          {/* 日付ごとのタブ */}
          {record.details.map((detail, index) => (
            <Tab
              title={toFormattedDate(detail.date)}
              eventKey={detail.recordId}
              key={detail.recordId}
            >
              <SDateDiv>
                日付：
                <MyDatePicker
                  onChange={(val) => {
                    if (val) {
                      detail.date = val;
                      setRecord({ ...record });
                    }
                  }}
                  value={detail.date}
                  canClear={false}
                  readOnly={!isEditing}
                />
              </SDateDiv>
              <SPanelDiv>
                <ParkEventPanel
                  data={detail}
                  eventType={periodEvent.eventType}
                  printRequested={() => printDetail(detail)}
                  readonly={!isEditing}
                  dataUpdated={(val) => {
                    record.details[index] = val as PeriodicalData;
                  }}
                  deleteDataRequested={(val) => {
                    record.details = record.details.filter((x) => x !== val);
                    setDeletedEvents([...deletedEvents, val as PeriodicalData]);
                    updateParticipants();
                    updateRevenue();
                  }}
                  saveDataRequested={async () => true}
                  participantsChanged={() => updateParticipants()}
                  revenueChanged={() => updateRevenue()}
                />
              </SPanelDiv>
            </Tab>
          ))}
          {isEditing && (
            <Tab
              title={
                <div className="fas fa-plus" onClick={addEventRequested}>
                  <span className="ps-1">追加</span>
                </div>
              }
            ></Tab>
          )}
        </Tabs>
      </BaseForm>
    </>
  );
};

const STopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SRightDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

const SDateDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  margin-top: -8px;
`;

const SPanelDiv = styled.div`
  margin-top: 25px;
`;
