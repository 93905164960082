export const theme = {
  color: {
    title: "rgb(32, 110, 169)",
    menuHeader: "#d7e1e6",
    menuSelectedBg: "#d6f5dd88",
    menuSelectedFg: "#226622",
    primaryButton: "#3b76cf",
    primaryButtonHover: "#46bff3",
    functionButton: "#5eae5e",
    functionButtonBorder: "#06761b",
    functionButtonDisabled: "#8a8d8a;",
    functionButtonHover: "#19b916",
    editButton: "#3b76cf",
    editButtonBorder: "#444",
    editButtonHover: "#46bff3",
    deleteButton: "#e6ba2a",
    deleteButtonBorder: "#765f14",
    deleteButtonHover: "#ffd036",
    disabled: "#727272",
  },
};
