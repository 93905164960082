import { useEffect, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import MyNumberInput from "./MyNumberInput";
import { MINUTE_STEP } from "common/types/consts/Defines";

type Props = {
  InitialValue: Date | null;
  TimeChanged: (dt: Date) => void;
  id?: string;
  readonly?: boolean;
  minuteStep?: number;
};

export const TimeInput = ({
  InitialValue,
  TimeChanged,
  id,
  readonly = false,
  minuteStep = MINUTE_STEP,
}: Props) => {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const onTimeChanged = (h: number, m: number) => {
    var dt = new Date();
    dt.setHours(h);
    dt.setMinutes(m);
    TimeChanged(dt);
  };

  useEffect(() => {
    setHour(InitialValue?.getHours() ?? 0);
    setMinutes(InitialValue?.getMinutes() ?? 0);
  }, [InitialValue]);

  return (
    <Stack direction="horizontal" gap={1} id={id}>
      <MyNumberInput
        className=""
        initialValue={hour}
        min={0}
        max={23}
        borderAlways={true}
        readonly={readonly ?? true}
        changed={(value) => {
          setHour(value);
          onTimeChanged(value, minutes);
        }}
      />
      <Form.Label> : </Form.Label>
      <MyNumberInput
        initialValue={minutes}
        min={0}
        max={59}
        borderAlways={true}
        step={minuteStep}
        readonly={readonly}
        changed={(value) => {
          setMinutes(value);
          onTimeChanged(hour, value);
        }}
      />
    </Stack>
  );
};
