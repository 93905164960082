import { Col, Form, Row } from "react-bootstrap";
import { WorkReportMeta } from "../types/WorkReportMeta";
import { StaffsList } from "./StaffsList";
import { NameIDList } from "common/components/molecules/NameIDList";
import { WorkReportData } from "../types/WorkReportData";
import { useEffect, useState } from "react";
import MyTextArea from "common/components/atoms/MyTextArae";

type Props = {
  meta: WorkReportMeta;
  data: WorkReportData;
  readonly: boolean;
  dataChanged: (data: WorkReportData) => void;
};

//=====================================
// 巡回レポート
//=====================================
export const PatrolReportPanel = (props: Props) => {
  const { meta, data, readonly, dataChanged } = props;
  const [report, setReport] = useState(data);

  const hasStaffs = () => {
    if (!meta) return true;
    return (
      meta.staffs.fulltime.length > 0 ||
      meta.staffs.parttime.length > 0 ||
      meta.staffs.tempstaff.length > 0
    );
  };

  useEffect(() => {
    setReport(data);
  }, [data]);

  return (
    <>
      <Row>
        <Col sm={12} md={4} className="mt-3">
          <Form.Label>巡回担当者</Form.Label>
          {hasStaffs() ? (
            <StaffsList
              lists={meta.staffs}
              selecteds={report.staffs}
              stringWhenEmpty="選択なし"
              readonly={readonly}
            />
          ) : (
            <div className="h6">担当者の定義がありません</div>
          )}
        </Col>
        <Col sm={12} md={4} className="mt-3">
          <Form.Label>巡回方法</Form.Label>
          {meta.patrolMethods?.length > 0 ? (
            <NameIDList
              lists={meta.patrolMethods}
              selecteds={report.patrolMethods}
              stringWhenEmpty="選択なし"
              readonly={readonly}
              maxHeight="200px"
              selectedChanged={(vals) => (report.patrolMethods = vals)}
            />
          ) : (
            <div className="h6">巡回方法の定義がありません</div>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} className="mt-3">
          <Form.Label>巡回地区</Form.Label>
          {meta.areas.length > 0 ? (
            <NameIDList
              lists={meta.areas}
              selecteds={report.patrolAreas}
              stringWhenEmpty="選択なし"
              readonly={readonly}
              maxHeight="200px"
              selectedChanged={(vals) => (report.patrolAreas = vals)}
            />
          ) : (
            <div className="h6">巡回地区の定義がありません</div>
          )}
        </Col>
        <Col sm={12} md={8}>
          <Form.Label className="mt-3">巡回地区詳細</Form.Label>
          <MyTextArea
            readOnly={readonly}
            value={report.patrolAreaMemo}
            onChange={(value) => {
              const newdata = { ...report, patrolAreaMemo: value };
              setReport(newdata);
              dataChanged(newdata);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3 d-flex justify-content-between">
            <Form.Label>巡回内容</Form.Label>
            <Form.Check
              readOnly={readonly}
              label="異常あり"
              type="switch"
              checked={report.anomalyDetected}
              onChange={(e) => {
                if (readonly) return;
                report.anomalyDetected = e.target.checked;
                setReport({ ...report });
              }}
            />
          </div>
          <MyTextArea
            readOnly={readonly}
            value={report.patrolMemo}
            onChange={(value) => {
              const newdata = { ...report, patrolMemo: value };
              setReport(newdata);
              dataChanged(newdata);
            }}
          />
        </Col>
        <Col className="mt-3" md={4}>
          <Form.Label>注意種別</Form.Label>
          {meta.warningTypes?.length > 0 ? (
            <NameIDList
              lists={meta.warningTypes}
              selecteds={report.warningTypes}
              stringWhenEmpty="選択なし"
              readonly={readonly}
              maxHeight="150px"
              selectedChanged={(vals) => (report.warningTypes = vals)}
            />
          ) : (
            <div>注意種別の定義がありません</div>
          )}
        </Col>
      </Row>
    </>
  );
};
