import { useCallback, useContext } from "react";
import axios from "axios";
import AuthContext from "common/store/AuthContext";
import { GetBasicResponse } from "common/types/responses/BasicResponse";
import { getServerUrl, generateErrorMsg } from "utils/commonTools";
import { TabInfoSet } from "../types/TabInfoSet";

export const usePark = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;
  
  //--------------------------------------
  // 公園で利用可能なタブの一覧を取得する
  //--------------------------------------
  const getTabs = useCallback(
    async (parkId: number): Promise<{ succeeded: boolean; msg: string; data?: TabInfoSet }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const { data } = await axios.get(getServerUrl() + `park/tabs/${parkId}`, config);
        const tabData = data as GetBasicResponse<TabInfoSet>;
        if (!tabData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        return { succeeded: true, msg: "", data: tabData.data };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return { getTabs };
};
