import { memo } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  title: string;
  onClick?: () => void;
  className?: string;
  value?: any;
  disabled?: boolean;
  size?: string;
  type?: string;
};

const PrimaryButton = memo((props: Props) => {
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick?.();
  };
  return (
    <SButton
      type={props.type}
      size={props.size ?? "md"}
      className={`${props.className} btn btn-info`}
      onClick={onClick}
      value={props.value}
      disabled={props.disabled ?? false}
    >
      {props.title}
    </SButton>
  );
});

export default PrimaryButton;

const SButton = styled(Button)`
  min-width: 6rem;
  background-color: ${(props) => props.theme.color.primaryButton};
  background-image: none;
  &.btn-primary:hover {
    background-color: ${(props) => props.theme.color.primaryButtonHover};
    background-image: none;
  }
`;
