import { Table } from "react-bootstrap";
import { Participant } from "../types/ParkEventData";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { InActiveStyle } from "common/types/styles/InActiveStyle";

type Props = {
  participants: Array<Participant> | null;
  readonly: boolean;
  updated?: () => void;
};

export const EventParticipantsTable = ({ participants, readonly, updated }: Props) => {
  return (
    <Table bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th className="col-md-1">種別</th>
          <th className="col-md-1">人数</th>
        </tr>
      </thead>
      <tbody>
        {participants &&
          participants.map((data) => (
            <tr
              key={`participant_${data.nameID.id}`}
              style={data.deleted ? InActiveStyle : undefined}
            >
              <td>{data.nameID.name}</td>
              <td>
                <MyNumberInput
                  readonly={readonly}
                  initialValue={data.count}
                  changed={(value) => {
                    data.count = value;
                    updated?.();
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
