import { useCallback, useContext } from "react";

import axios from "axios";
import AuthContext from "common/store/AuthContext";
import { generateErrorMsg, getServerUrl } from "utils/commonTools";

import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";
import { EditTabResponse } from "common/types/responses/EditTabResponse";

export const useTabEditing = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 編集を開始する
  //--------------------------------------
  const startEditing = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      tabId: TAB_ID,
      key: string
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: EditTabResponse;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url = getServerUrl() + `park/edit/start/${parkId}/${reportType}/${tabId}?key=${key}`;

        const { data } = await axios.post(url, {}, config);
        const result = data as EditTabResponse;
        if (!result) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        return {
          succeeded: true,
          msg: "",
          data: result,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 編集の開始依頼をする
  //--------------------------------------
  const askForStartEditing = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      tabId: TAB_ID,
      key: string
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: EditTabResponse;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url =
          getServerUrl() + `park/edit/forceEdit/${parkId}/${reportType}/${tabId}?key=${key}`;

        const { data } = await axios.post(url, {}, config);

        return {
          succeeded: true,
          msg: "",
          data,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 編集を開始する
  //--------------------------------------
  const endEditing = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      tabId: TAB_ID,
      key: string
    ): Promise<{
      succeeded: boolean;
      msg: string;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url = getServerUrl() + `park/edit/end/${parkId}/${reportType}/${tabId}?key=${key}`;

        const { status } = await axios.post(url, {}, config);
        switch (status) {
          case 200:
            return { succeeded: true, msg: "" };
          default:
            return { succeeded: false, msg: "編集を終了できませんでした" };
        }
      } catch (error: any) {
        if (error?.request?.status === 409)
          return { succeeded: false, msg: "編集状態ではありません" };
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 強制編集開始に対して返答をする
  //--------------------------------------
  const answerForceEditing = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      tabId: TAB_ID,
      key: string,
      accept: boolean
    ): Promise<{
      succeeded: boolean;
      msg: string;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url =
          getServerUrl() +
          `park/edit/forceEdit/answer/${parkId}/${reportType}/${tabId}?key=${key}&accept=${
            accept ? 1 : 0
          }`;

        const { status } = await axios.post(url, {}, config);
        switch (status) {
          case 200:
            return { succeeded: true, msg: "" };
          default:
            return { succeeded: false, msg: "強制編集の回答ができませんでした" };
        }
      } catch (error: any) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  return { startEditing, askForStartEditing, endEditing, answerForceEditing };
};
