import { useContext, useEffect, useState } from "react";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { Form } from "react-bootstrap";

import { usePrintTemplate } from "./hooks/usePrintTemplate";
import { NameID } from "common/types/NameID";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";
import { INVALID_ID } from "common/types/consts/Defines";
import { PRINT_TYPE } from "./types/PRINT_TYPE";
import { PrintDailyControl } from "./components/PrintDailyControl";
import { PrintMonthlyControl } from "./components/PrintMonthlyControl";
import { PrintInquiryControl } from "./components/PrintInquiryControl";
import { PrintAggregateControl } from "./components/PrintAggregateControl";
import { PrintCustomControl } from "./components/PrintCustomControl";

let isInitializing = false;

//=====================================
// 帳票出力ページ
//=====================================
export const PrintPage = (props: { pageTitle: string }) => {
  const { selectedPark } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const { getAvailabePrintTypes } = usePrintTemplate();
  const [selectedPrint, setSelectedPrint] = useState(INVALID_ID);

  const [printTypes, setPrintTypes] = useState<NameID[]>([]);

  //-------------------------------------
  // 帳票登録ページ
  //-------------------------------------
  const loadPrintTypes = async () => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("帳票テンプレート一覧の取得中");

    const { succeeded, msg, data } = await getAvailabePrintTypes(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      setPrintTypes(data ?? []);
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  useEffect(() => {
    document.title = props.pageTitle;
    const f = async () => {
      if (isInitializing) return;

      isInitializing = true;
      await loadPrintTypes();
      isInitializing = false;
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrintControl = () => {
    const selected = printTypes.find((x) => x.id === selectedPrint);
    if (!selected) return; // 発生しない

    switch (selectedPrint) {
      case INVALID_ID:
        return <></>;
      case PRINT_TYPE.PARK_DAILY:
        return <PrintDailyControl title={selected.name} />;
      case PRINT_TYPE.MONTHLY:
        return <PrintMonthlyControl title={selected.name} />;
      case PRINT_TYPE.PRIVATE_BIZ:
      case PRINT_TYPE.COOP_BIZ:
      case PRINT_TYPE.PERIOD_EVENT:
        return (
          <ShowEmpty>
            このページからの出力はサポートされていません。
            <br /> データの入力ページから出力を実行してください。
          </ShowEmpty>
        );
      case PRINT_TYPE.INQUIRIES:
        return <PrintInquiryControl title={selected.name} />;
      case PRINT_TYPE.AGGREGATE:
        return <PrintAggregateControl title={selected.name} />;
      default:
        if (selectedPrint >= 10000) {
          return <PrintCustomControl title={selected.name} templateId={selectedPrint} />;
        }
        return <ShowEmpty>選択された出力はサポートされていません</ShowEmpty>;
    }
  };

  return (
    <DashboardTemplate>
      <FormContainer size="max">
        {!selectedPark && <div> 公園が選択されていません </div>}

        {/* タイトル */}
        <Form className="mb-1 pb-1">
          <div className="mt-3 mb-1 ms-2 d-flex align-items-center" style={{ minWidth: "200px" }}>
            <span className="h3">
              {selectedPark?.parkName} : {props.pageTitle}{" "}
            </span>
          </div>
        </Form>

        <BaseForm className="p-4">
          {printTypes.length === 0 ? (
            <ShowEmpty>帳票の設定がありません</ShowEmpty>
          ) : (
            <div>
              <h4 className="mb-2 mx-2">帳票出力</h4>
              <Form.Select
                className="mb-2"
                style={{ width: "250px" }}
                aria-label="帳票出力"
                value={selectedPrint}
                onChange={(e) => {
                  setSelectedPrint(+e.currentTarget.value);
                }}
              >
                <option value={INVALID_ID}>出力する帳票を選択</option>
                {printTypes.map((pt) => {
                  return (
                    <option key={`print_${pt.id}`} value={pt.id}>
                      {pt.name}
                    </option>
                  );
                })}
              </Form.Select>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                {getPrintControl()}
              </div>
            </div>
          )}
        </BaseForm>
      </FormContainer>
    </DashboardTemplate>
  );
};
