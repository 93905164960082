import { v4 as uuidv4 } from "uuid";

import { useParkEventData } from "pages/parkevents/hooks/useParkEventData";
import { PeriodicalData, PeriodicalEvent, PeriodicalEventRecord } from "../types/PeriodicalEvent";
import { NameID } from "common/types/NameID";
import { EVENT_TYPE } from "pages/parkevents/types/EVENT_TYPE";

export const usePeriodData = () => {
  const { createEmptyParkEvent } = useParkEventData();
  /**
   * Used when creating a new data
   * @returns
   */
  const createEmptyEvent = (participants?: NameID[]): PeriodicalEvent => {
    return {
      eventId: -1,
      title: "",
      eventType: EVENT_TYPE.Independent,
    };
  };

  const createEmptyRecord = (participants?: NameID[]): PeriodicalEventRecord => {
    return {
      summary: createEmptyParkEvent(EVENT_TYPE.Independent, participants),
      details: [],
    };
  };

  const createEmptyDetail = (participants?: NameID[]): PeriodicalData => {
    return {
      ...createEmptyParkEvent(EVENT_TYPE.Independent, participants),
      date: new Date(),
      recordId: uuidv4(),
    } as PeriodicalData;
  };

  const getEventTypeString = (eventType: EVENT_TYPE): string => {
    switch (eventType) {
      case EVENT_TYPE.Independent:
        return "自主事業";
      case EVENT_TYPE.Collaborate:
        return "協働事業";
      case EVENT_TYPE.Event:
        return "イベント";
      default:
        return "不明";
    }
  };

  return { createEmptyEvent, createEmptyRecord, createEmptyDetail, getEventTypeString };
};
