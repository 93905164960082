import { Col, Row, Table } from "react-bootstrap";

import { InquirySummary } from "../types/InquiryInfo";

type Props = {
  Summaries: InquirySummary[] | null;
};

export const InquirySummaryTable = ({ Summaries }: Props) => {
  return (
    <>
      <h4>合計件数</h4>
      <Row>
        <Col xs={12} md={6}>
          <Table bordered hover responsive className="summary-table">
            <thead>
              <tr className="text-center">
                <th className="col-md-2">要求種別</th>
                <th className="col-md-1">来園</th>
                <th className="col-md-1">電話</th>
                <th className="col-md-1">Eメール</th>
                <th className="col-md-1">その他</th>
                <th className="col-md-1">合計</th>
              </tr>
            </thead>
            <tbody>
              {Summaries &&
                Summaries.map((x) => {
                  return (
                    <tr
                      key={`sum_${x.typeID}`}
                      className={`text-end ${x.typeID === -1 && "row-total"}`}
                    >
                      <td className="text-start">{x.typeName}</td>
                      <td>{x.visit}</td>
                      <td>{x.phone}</td>
                      <td>{x.email}</td>
                      <td>{x.others}</td>
                      <td>{x.total}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
