import { useState, useEffect, memo, ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import styled from 'styled-components'

type Props = {
  initialValue: boolean;
  title?:string;
  changed?: (changed: boolean) => void;
  readonly?: boolean;
};

export const MySwitch = memo((props: Props) => {
  const { initialValue, title = "", changed, readonly = false } = props;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return (
    <SSwitch
      type="switch"
      checked={checked}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        //e.preventDefault();
        setChecked(e.target.checked);
        changed && changed(e.target.checked);
      }}
      label={title}
      disabled={readonly}
      className="border-info"
    />
  );
});

export default MySwitch;

const SSwitch = styled(Form.Switch)`
&::before {
  background: red
}
`;