import { NameID } from "common/types/NameID";

export const useAreas = () => {
  const toAreaString = (areas?: NameID[]) => {
    if (areas === undefined || areas.length === 0) return "";
    return areas.map((area) => area.name).join("\n");
  };

  return { toAreaString };
};
