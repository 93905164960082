import { Table } from "react-bootstrap";
import FunctionButton from "common/components/atoms/FunctionButton";

import { PayFacilityUsage } from "../types/PayFacilityData";
import { PayFacilityMeta } from "../types/PayFacilityMeta";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { MultiLineText } from "common/components/atoms/MultiLineText";

type Props = {
  Usages: Array<PayFacilityUsage> | null;
  Meta: PayFacilityMeta;
  readonly: boolean;
  addDataRequested: () => void;
  editDataRequested: (data: PayFacilityUsage, index: number) => void;
  deleteDataRequested: (data: PayFacilityUsage, index: number) => void;
};

export const PayFacilityDetailTable = ({
  Usages,
  Meta,
  readonly,
  addDataRequested,
  editDataRequested,
  deleteDataRequested,
}: Props) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        <h4>{}</h4>
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addDataRequested();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>
      <Table bordered hover responsive>
        <thead>
          <tr className="text-center">
            {Meta.usageTypes.length > 0 && <th className="col-md-1">利用区分</th>}
            {Meta.periodTypes.length > 0 && <th className="col-md-1">時間区分</th>}
            {Meta.exemptionTypes.length > 0 && <th className="col-md-1">減免適用</th>}
            {Meta.fees.length > 0 && <th className="col-md-1">支払方法</th>}
            <th className="col-md-1">利用件数</th>
            <th className="col-md-1">利用人数</th>
            {Meta.fees.length > 0 && <th className="col-md-1">利用料金</th>}
            <th className="col-md-*">備考</th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {Usages &&
            Usages.map((x, index) => (
              <tr key={`payfacuse_${index}`}>
                {Meta.usageTypes.length > 0 && <td className="text-center">{x.usageType?.name}</td>}
                {Meta.periodTypes.length > 0 && (
                  <td className="text-center"> {x.periodType?.name} </td>
                )}
                {Meta.exemptionTypes.length > 0 && (
                  <td className="text-center">{x.exemptionType?.name}</td>
                )}
                {Meta.fees.length > 0 && <td className="text-center">{x.paymentType?.name}</td>}
                <td className="text-end">{x.count}</td>
                <td className="text-end">{x.numUser}</td>
                {Meta.fees.length > 0 && (
                  <td className="text-end">&yen;{x.fee.toLocaleString()}</td>
                )}
                <td className="text-start">
                  <MultiLineText text={x.note} />{" "}
                </td>
                {!readonly && (
                  <td className="text-center">
                    <RowEditButtons
                      editRequested={() => editDataRequested(x, index)}
                      deleteRequested={() => deleteDataRequested(x, index)}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};
