import { useContext } from "react";
import axios, { AxiosRequestConfig } from "axios";
import AuthContext from "common/store/AuthContext";
import { FileUploadResponse } from "common/types/responses/FileUploadResponse";
import { generateErrorMsg, getServerUrl } from "utils/commonTools";

export const useFiles = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // ファイルのアップロード処理
  //--------------------------------------
  const uploadFile = async (
    parkId: number,
    file: File
  ): Promise<{ succeeded: boolean; msg: string; fileId?: number }> => {
    const formData = new FormData();
    formData.append("file", file!);
    //console.log("file", file)
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const { data } = await axios.post(getServerUrl() + `media/files/${parkId}`, formData, config);
      const registered = data as FileUploadResponse;
      if (!registered) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      return { succeeded: true, msg: "", fileId: registered.fileId };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  //--------------------------------------
  // ファイルの削除
  //--------------------------------------
  const deleteFile = async (
    parkId: number,
    fileId: number
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };

    try {
      await axios.delete(getServerUrl() + `media/files/${parkId}/${fileId}`, config);
      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: "ファイルの削除に失敗しました。" + errMsg };
    }
  };

  //--------------------------------------
  // ファイルのダウンロード処理
  //--------------------------------------
  const downloadFile = async (
    parkId: number,
    fileId: number,
    fileName: string
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      responseType: "blob",
    } as AxiosRequestConfig;

    try {
      const response = await axios.get(getServerUrl() + `media/files/${parkId}/${fileId}`, config);
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  //--------------------------------------
  // 写真のダウンロード処理
  //--------------------------------------
  const getPicture = async (
    parkId: number,
    fileId: number,
  ): Promise<{ succeeded: boolean; data: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      responseType: "blob",
    } as AxiosRequestConfig;

    try {
      const response = await axios.get(getServerUrl() + `media/files/${parkId}/${fileId}`, config);
      return {succeeded: true, data: window.URL.createObjectURL(response.data) };

    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, data: errMsg };
    }
  };

  return { uploadFile, deleteFile, downloadFile, getPicture };
};
