import { ReactNode, memo } from 'react'
import { Alert } from 'react-bootstrap'

type Props = {
    variant: string;
    children: ReactNode;
    className?: string;
    closeRequested?: () => void;
};

const MyAlert = memo((props: Props) => {
    const {variant, children, className, closeRequested} = props;
    
    return (
        <Alert variant={variant} className={className} onClose={closeRequested} dismissible={closeRequested !== undefined}>
            {children}
        </Alert>
    )
});

export default MyAlert
