import { useContext, useEffect, useState } from "react";
import { PageTop } from "common/components/organisms/PageTop";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { BaseForm } from "common/components/templates/BaseForm";
import { ExclusiveUsesSummaryTable } from "./components/ExclusiveUsesSummaryTable";
import { ExclusiveUsesData, ExclusiveUsesSummary } from "./types/ExclusiveUsesReport";
import { createDocumentTitle } from "utils/commonTools";
import { ExclusiveUsesMeta } from "./types/ExclusiveUsesMeta";

import { useExclusiveUses } from "./hooks/useExclusiveUses";
import { useExclusiveUsesData } from "./hooks/useExclusiveUsesData";
import { ExclusiveUsesDetailTable } from "./components/ExclusiveUsesDetailTable";
import { DataEditTarget } from "../../common/types/DataEditTarget";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { EditExclusiveUsesDlg } from "./components/EditExclusiveUsesDlg";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";
let isInitializing = false;

//=====================================
// 一時占用ページ
//=====================================
type Props = {
  pageTitle: string;
};

const ExclusiveUsesPage = ({ pageTitle }: Props) => {
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);
  const [metaData, setMetaData] = useState<ExclusiveUsesMeta | null>(null);
  const [summaries, setSummaries] = useState<ExclusiveUsesSummary[] | null>(null);
  const [details, setDetails] = useState<ExclusiveUsesData[]>([]);

  const { loadMetaData, getReportData, saveReportData } = useExclusiveUses();
  const { createEmptySummaries, updateSummaries, createEmptyData } = useExclusiveUsesData();

  const [editTarget, setEditTarget] = useState<DataEditTarget<ExclusiveUsesData> | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<ExclusiveUsesData | null>(null);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<ExclusiveUsesMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: ExclusiveUsesMeta) => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );
    if (!baseData) setSummaries(null);

    const summaries = createEmptySummaries(baseData.usageTypes);
    if (data?.details) {
      setSummaries(updateSummaries(summaries, data.details));
    } else {
      setSummaries(summaries);
    }
    setDetails(data?.details ?? []);
  };

  //--------------------------------------
  // データの追加
  //--------------------------------------
  const addDataRequested = () => {
    const newData = createEmptyData();
    setEditTarget({ index: -1, data: newData });
  };

  //--------------------------------------
  // データの編集
  //--------------------------------------
  const editDataRequested = (data: ExclusiveUsesData, index: number) => {
    setEditTarget({ index, data });
  };

  const addOrEditCompleted = (data: ExclusiveUsesData) => {
    if (!editTarget) return;

    let newArray = details;
    if (!newArray) newArray = [];

    if (editTarget.index < 0) {
      // 追加
      newArray?.push(data);
      setDetails(newArray);
    } else {
      // 編集
      newArray![editTarget.index] = data;
      setDetails(newArray);
    }

    setSummaries(updateSummaries(summaries!, newArray));

    setEditTarget(null);
  };

  //--------------------------------------
  // データの削除
  //--------------------------------------
  const deleteDataRequested = (data: ExclusiveUsesData) => {
    setDeleteTarget(data);
  };

  const confirmDeleteOK = async () => {
    var newList = details!.filter((x) => x !== deleteTarget);
    setDetails(newList);
    setSummaries(updateSummaries(summaries!, newList));
    setDeleteTarget(null);
  };

  //--------------------------------------
  // 保存
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !summaries) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      currentDate,
      {
        summaries,
        details,
      },
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadData = async () => {
    if (metaData) await loadReportData(metaData);
  };

  return (
    <DashboardTemplate>
      <PageTop
        title={pageTitle}
        reportType={REPORT_TYPE.DAILY}
        tabId={TAB_ID.EXCLUSIVE_USE}
        lastUpdated={lastUpdated}
        onEditStart={async () => await reloadData()}
        onSave={!readOnly ? onSave : undefined}
        onCancel={async () => await reloadData()}
      />
      <BaseForm className="p-3">
        <ExclusiveUsesSummaryTable Summaries={summaries} />
        <ExclusiveUsesDetailTable
          Details={details}
          readonly={!isEditing}
          addDataRequested={addDataRequested}
          editDataRequested={editDataRequested}
          deleteDataRequested={deleteDataRequested}
        />
      </BaseForm>
      {metaData && (
        <EditExclusiveUsesDlg
          target={editTarget}
          metaData={metaData}
          onOK={addOrEditCompleted}
          onCancel={() => setEditTarget(null)}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={["選択したデータを削除しても良いですか"]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
    </DashboardTemplate>
  );
};

export default ExclusiveUsesPage;
