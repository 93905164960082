import { Table } from "react-bootstrap";
import MyCheckbox from "common/components/atoms/MyCheckbox";
import FunctionButton from "common/components/atoms/FunctionButton";

import { InquiryData } from "../types/InquiryInfo";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { MultiLineText } from "common/components/atoms/MultiLineText";

type Props = {
  Details: Array<InquiryData> | null;
  readonly: boolean;
  addDataRequested: () => void;
  editDataRequested: (data: InquiryData, index: number) => void;
  deleteDataRequested: (data: InquiryData) => void;
  showTitle?: boolean;
};

export const InquiryDetailTable = ({
  Details,
  readonly,
  addDataRequested,
  editDataRequested,
  deleteDataRequested,
  showTitle = true,
}: Props) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        {showTitle && <h4>詳細入力</h4>}
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addDataRequested();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>
      <Table bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th className="col-md-1" hidden={true}>
              整理番号
            </th>
            <th className="col-md-1">要求種別</th>
            <th className="col-md-1">受付種別</th>
            <th className="col-md-1">受付者</th>
            <th className="col-md-*">内容</th>
            <th className="col-md-*">対応内容</th>
            <th className="col-md-1">件数</th>
            <th className="col-md-1">完了</th>
            <th className="col-md-1">報告</th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {Details &&
            Details.map((x, index) => (
              <tr key={`inq_${index}`}>
                <td className="text-center" hidden={true}>
                  {x.inquiryNo}
                </td>
                <td className="text-center">
                  {x.category?.name}
                  {x.subCategory?.name && `/${x.subCategory?.name}`}
                </td>
                <td className="text-center">{x.method?.name}</td>
                <td className="text-center">{x.recipient?.name}</td>
                <td>
                  <MultiLineText text={x.contents} />
                </td>
                <td>
                  <MultiLineText text={x.action} />
                </td>
                <td className="text-end">{x.numIncident}</td>
                <td className="text-center">
                  <MyCheckbox initialValue={x.completed} readonly={true} />
                </td>
                <td className="text-center">
                  <MyCheckbox initialValue={x.reported} readonly={true} />
                </td>
                {!readonly && (
                  <td className="text-center">
                    <RowEditButtons
                      editRequested={() => editDataRequested(x, index)}
                      deleteRequested={() => deleteDataRequested(x)}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};
