import { ReactNode, useContext, useEffect } from "react";
import { Row, Stack, ToastContainer } from "react-bootstrap";
import styled from "styled-components";

import StatusContext from "common/store/StatusContext";

import MyAlert from "../atoms/MyAlert";

import Topbar from "../organisms/Topbar";
import Footer from "../organisms/Footer";
import MyToast from "../atoms/MyToast";

type Props = {
  children: ReactNode;
};

export const BasicTemplate = (props: Props) => {
  const { errorMessage, setErrorMessage, toastTitle, toastMessage, setToastMessage } =
    useContext(StatusContext);

  useEffect(() => {
    setErrorMessage("");
  }, [setErrorMessage]);

  return (
    <div>
      <Row className="fixed-top" style={{ zIndex: 1 }}>
        <Topbar />
      </Row>
      <ContentRow className="">{props.children}</ContentRow>
      <ToastContainer className="p-3 fixed-top ms-auto" position="top-end" style={{ zIndex: 2 }}>
        {toastMessage && (
          <MyToast
            variant="success"
            title={toastTitle}
            message={toastMessage}
            onClose={async () => setToastMessage("")}
            duration={3000}
          ></MyToast>
        )}
      </ToastContainer>
      <SMessageStack className="fixed-top py-0">
        {errorMessage && (
          <MyAlert variant="danger" closeRequested={() => setErrorMessage("")}>
            {errorMessage}
          </MyAlert>
        )}
      </SMessageStack>

      <Footer className="fixed-bottom" />
    </div>
  );
};

const ContentRow = styled(Row)`
  padding-top: 56px;
  margin-left: 20px;
  margin-right: 20px;
`;
const SMessageStack = styled(Stack)`
  margin-top: 56px;
`;
