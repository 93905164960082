import { Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { ExclusiveUsesSummary } from "../types/ExclusiveUsesReport";

type Props = {
  Summaries: ExclusiveUsesSummary[] | null;
};

export const ExclusiveUsesSummaryTable = ({ Summaries }: Props) => {
  return (
    <>
      <h4>占用合計</h4>
      <Row>
        <Col>
          <STable bordered hover className="summary-table">
            <thead>
              <tr className="text-center">
                <th>占用種別</th>
                <th>占用件数</th>
                <th>占用料金</th>
              </tr>
            </thead>
            <tbody>
              {Summaries &&
                Summaries.map((x) => {
                  return (
                    <tr
                      key={`sum_${x.usageType.id}`}
                      className={`text-end ${x.usageType.id === -1 && "row-total"}`}
                    >
                      <td className="text-start">{x.usageType.name}</td>
                      <td>{x.count}</td>
                      <td>{x.sales}</td>
                    </tr>
                  );
                })}
            </tbody>
          </STable>
        </Col>
      </Row>
    </>
  );
};

const STable = styled(Table)`
  width: 300px;
`;
