import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Form, Row, Table } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import FunctionButton from "common/components/atoms/FunctionButton";
import { addDays, addMonths, toFormattedDate } from "utils/DateTools";

import { usePeriodList } from "../hooks/usePeriodList";
import { usePeriodData } from "../hooks/usePeriodData";
import { PeriodicalEvent } from "../types/PeriodicalEvent";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { EditPeriodicalEventDlg } from "./EditPeriodicalEventDlg";
import { EVENT_TYPE } from "pages/parkevents/types/EVENT_TYPE";
import { MyDatePicker } from "common/components/atoms/MyDatePicker";

type Props = {
  title: string;
  showDetailRequested: (ev: PeriodicalEvent) => void;
};

let isLoading = false;

// =======================================
// イベント一覧の中身
// =======================================
export const PeriodEventList = (props: Props) => {
  const { title, showDetailRequested } = props;
  const { getPeriodEvents, createEvent, updateEvent, getEventData, deleteEvent } = usePeriodList();
  const { createEmptyEvent, getEventTypeString } = usePeriodData();
  const { selectedPark, readOnly } = useContext(AuthContext);
  const { setErrorMessage, showToastMessage } = useContext(StatusContext);

  const [events, setEvents] = useState<PeriodicalEvent[]>([]);
  const [editTarget, setEditTarget] = useState<PeriodicalEvent>();
  const [deleteTarget, setDeleteTarget] = useState<PeriodicalEvent>();
  const [selectedEvent, setSelectedEvent] = useState<PeriodicalEvent>();

  const [typeFilter, setTypeFilter] = useState<EVENT_TYPE>();

  const today = new Date();
  const [dateFrom, setDateFrom] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [dateTo, setDateTo] = useState(addDays(addMonths(dateFrom, 1), -1));

  const onEventClick = async (ev: PeriodicalEvent) => {
    if (!selectedPark) return;
    const { succeeded, msg, data } = await getEventData(selectedPark.parkId, ev.eventId);
    if (succeeded) {
      ev.record = data;
      setSelectedEvent(ev);
    } else setErrorMessage(msg);
  };

  const onAddEventClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const newEvent = createEmptyEvent();
    setEditTarget(newEvent);
  };

  const editDataCompleted = async (data: PeriodicalEvent) => {
    if (!selectedPark) return;

    if (data.eventId < 0) {
      // add new
      const res = await createEvent(selectedPark.parkId, data);
      if (res.succeeded) {
        setEvents([...events, res.data!]);
        showToastMessage("イベント追加", `${data.title}を追加しました`);
      } else {
        setErrorMessage(res.msg);
        return;
      }
    } else {
      // edit
      const res = await updateEvent(selectedPark.parkId, data);
      if (res.succeeded) {
        const index = events.findIndex((x) => x.eventId === data.eventId);
        if (index < 0) return; // should not happen
        events[index] = data;
        setEvents([...events]);
        showToastMessage("イベント編集", `${data.title}を更新しました`);
      } else {
        setErrorMessage(res.msg);
        return;
      }
    }
    setEditTarget(undefined);
  };

  const deleteConfirmed = async () => {
    if (!deleteTarget || !selectedPark) return; // should not happen

    const res = await deleteEvent(selectedPark.parkId, deleteTarget.eventId);
    if (res.succeeded) {
      showToastMessage("イベント削除", `${deleteTarget.title}を削除しました`);
      setEvents(events.filter((x) => x !== deleteTarget));
      setDeleteTarget(undefined);
    } else {
      setErrorMessage("イベントの削除に失敗しました。" + res.msg);
    }
  };

  const loadEvents = async () => {
    if (!selectedPark) return;

    const res = await getPeriodEvents(selectedPark.parkId, dateFrom, dateTo);
    if (res.succeeded) setEvents(res.data ?? []);
    else setEvents([]);
  };

  useEffect(() => {
    if (!isLoading) {
      isLoading = true;
      const f = async () => {
        await loadEvents();
        isLoading = false;
      };

      f();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPark]);

  useEffect(() => {
    const f = async () => loadEvents();
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);
  return (
    <>
      <STopDiv className="my-3 mx-2">
        {/* タイトル */}
        <span className="h4">{title}</span>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem" }}>
          {/* 追加ボタン */}
          {!readOnly && (
            <FunctionButton onClick={onAddEventClick} className="ms-2" tooltip="追加" size="sm">
              <i className="fa-regular fa-plus"></i>
            </FunctionButton>
          )}
          {/* 詳細表示ボタン */}
          {!readOnly && (
            <FunctionButton
              size="none"
              onClick={() => selectedEvent && showDetailRequested(selectedEvent)}
              tooltip="詳細表示"
              disabled={!selectedEvent}
            >
              詳細表示
            </FunctionButton>
          )}
        </div>
      </STopDiv>
      <SRow>
        <SFilterDiv>
          <span className="h6">期間：</span>
          <div>
            <MyDatePicker
              value={dateFrom}
              onChange={(val) => val && setDateFrom(val)}
              canClear={false}
              style={{ width: "120px" }}
            />
            <span> ～ </span>
            <MyDatePicker
              value={dateTo}
              onChange={(val) => val && setDateTo(val)}
              canClear={false}
              style={{ width: "120px" }}
            />
          </div>
          <div>
            <span className="h6">種別：</span>
            <Form.Select
              style={{
                width: "120px",
                fontSize: "14px",
              }}
              value={typeFilter}
              onChange={(e) => {
                e.preventDefault();
                if (e.currentTarget.value === "-1") setTypeFilter(undefined);
                else setTypeFilter(+e.currentTarget.value);
              }}
            >
              <option value={-1} key="evType_undefined">
                なし
              </option>
              <option value={EVENT_TYPE.Independent} key={`evType_0`}>
                {getEventTypeString(EVENT_TYPE.Independent)}
              </option>
              <option value={EVENT_TYPE.Collaborate} key={`evType_1`}>
                {getEventTypeString(EVENT_TYPE.Collaborate)}
              </option>
              <option value={EVENT_TYPE.Event} key={`evType_2`}>
                {getEventTypeString(EVENT_TYPE.Event)}
              </option>
            </Form.Select>
          </div>
        </SFilterDiv>
      </SRow>
      <SRow>
        <SCol lg={8}>
          {events.length === 0 ? (
            <SNoData>イベントが登録されていません</SNoData>
          ) : (
            <Table bordered hover>
              <thead className="text-center">
                <tr>
                  <th>タイトル</th>
                  <th>種別</th>
                  <th className="col-md-3">開始</th>
                  <th className="col-md-3">終了</th>
                  {!readOnly && <th style={{ width: "100px" }}>操作</th>}
                </tr>
              </thead>
              <tbody>
                {events.map((ev) =>
                  typeFilter === undefined || typeFilter === ev.eventType ? (
                    <tr
                      key={`eventList_${ev.eventId}`}
                      className={`${ev === selectedEvent ? "selectedRow" : ""}`}
                      onClick={() => onEventClick(ev)}
                    >
                      <StdLeft>{ev.title}</StdLeft>
                      <StdCenter>{getEventTypeString(ev.eventType)}</StdCenter>
                      <StdCenter>{toFormattedDate(ev.startDate)}</StdCenter>
                      <StdCenter>{toFormattedDate(ev.endDate)}</StdCenter>
                      {!readOnly && (
                        <td className="text-center py-1">
                          <RowEditButtons
                            editRequested={() => {
                              setEditTarget(ev);
                            }}
                            deleteRequested={() => setDeleteTarget(ev)}
                            editTooltip="タイトルや期間を変更します"
                          />
                        </td>
                      )}
                    </tr>
                  ) : (
                    <></>
                  )
                )}
              </tbody>
            </Table>
          )}
        </SCol>

        {/* イベントに対する登録日付一覧 */}
        <SCol lg={4}>
          {!selectedEvent || !selectedEvent.record || selectedEvent.record.details.length === 0 ? (
            <SNoData>登録されたデータはありません</SNoData>
          ) : (
            <Table bordered hover style={{ width: "200px" }}>
              <thead className="text-center">
                <tr>
                  <th>登録データ</th>
                </tr>
              </thead>
              <tbody>
                {selectedEvent.record.details.map((record) => (
                  <tr key={`dateList_${record.recordId}`}>
                    <StdCenter>{toFormattedDate(record.date)}</StdCenter>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </SCol>
      </SRow>
      {editTarget && (
        <EditPeriodicalEventDlg
          target={editTarget}
          pageTitle={title}
          onCancel={() => setEditTarget(undefined)}
          onOK={editDataCompleted}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={["選択したデータを削除しても良いですか", deleteTarget.title]}
          onOk={deleteConfirmed}
          onCancel={async () => {
            setDeleteTarget(undefined);
          }}
        />
      )}
    </>
  );
};

const STopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.25rem;
    z-index: 1021; // thead の sticky-top が1020となっている
  }
`;

const SNoData = styled.h6`
  margin: 1rem;
`;

const SRow = styled(Row)`
  font-size: smaller;
  td {
    cursor: pointer;
  }
`;

const SCol = styled(Col)`
  overflow-y: auto;
`;

const StdLeft = styled.td`
  vertical-align: middle;
`;

const StdCenter = styled.td`
  text-align: center;
  vertical-align: middle;
`;
