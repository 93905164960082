import { useState } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import MyNumberInput from "common/components/atoms/MyNumberInput";
import { InActiveStyle } from "common/types/styles/InActiveStyle";

import { VisitorData } from "../types/VisitorData";

type Props = {
  title: string;
  columnTitle: string;
  data: Array<VisitorData>;
  readOnly?: boolean;
};

export const VisitorTable = (props: Props) => {
  // 一時的に再レンダリングさせるためのダミー
  const [, setUpdate] = useState<any>();
  const { title, columnTitle, data, readOnly = false } = props;

  return (
    <>
      <h4 className="mb-2">{title}</h4>
      <Table bordered hover>
        <thead className="text-center">
          <tr>
            <Sth0>{columnTitle}</Sth0>
            {data.length > 0 &&
              data[0].counts.map((x) => (
                <Sth key={`period_header_${x.period.id}`}> {x.period.name}</Sth>
              ))}
            <Sth>合計</Sth>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              <tr key={`row_${row.id}`} style={row.inactive ? InActiveStyle : undefined}>
                <td>{row.name}</td>
                {row.counts.map((x) =>
                  readOnly ? (
                    <td className="text-end" key={`period_${x.period.id}_${x.period.name}`}>
                      {x.count}
                    </td>
                  ) : (
                    <td key={`period_${x.period.id}_${x.period.name}`}>
                      <SMyNumberInput
                        initialValue={x.count}
                        // changed={(number) => (setAm(number))
                        changed={(number) => {
                          x.count = +number;
                          row.total = row.counts.reduce((prev, next) => prev + next.count, 0);
                          setUpdate(x.count);
                        }}
                        readonly={readOnly}
                      />
                    </td>
                  )
                )}
                <td className="text-end">{row.total}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const SMyNumberInput = styled(MyNumberInput)`
  width: 100%;
`;

const Sth0 = styled.th`
  max-width: 150px;
  min-width: 100px;
`;

const Sth = styled.th`
  max-width: 120px;
  min-width: 80px;
`;
