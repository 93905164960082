import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import FunctionButton from "common/components/atoms/FunctionButton";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { EditTaskDlg } from "./EditTaskDlg";
import { WorkReportMeta } from "../types/WorkReportMeta";
import { Task } from "../types/WorkReportData";
import { useWorkReportData } from "../hooks/useWorkReportData";
import { INVALID_ID } from "common/types/consts/Defines";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";
import { useAreas } from "common/hooks/useAreas";
import { MultiLineText } from "common/components/atoms/MultiLineText";

type Props = {
  tasks: Task[];
  meta: WorkReportMeta;
  readonly: boolean;
  tasksChanged: (tasks: Task[]) => void;
};

export const TaskReportPanel = (props: Props) => {
  const { meta, readonly, tasksChanged } = props;
  const [tasks, setTasks] = useState<Task[]>(props.tasks);
  const [editTarget, setEditTarget] = useState<Task | null>(null);
  const [targetIndex, setTargetIndex] = useState(INVALID_ID);
  const [deleteTarget, setDeleteTarget] = useState<Task | null>(null);
  const { createEmptyTask } = useWorkReportData();
  const { toAreaString } = useAreas();

  const onAddData = () => {
    const newTask = createEmptyTask();
    setEditTarget(newTask);
    setTargetIndex(INVALID_ID);
  };

  const onEditData = (task: Task, index: number) => {
    setEditTarget({ ...task });
    setTargetIndex(index);
  };

  const onEditDataCompleted = (task: Task) => {
    let newTasks: Task[];
    if (targetIndex === INVALID_ID) {
      newTasks = [...tasks, task];
    } else {
      newTasks = [...tasks];
      newTasks[targetIndex] = task;
    }
    setTasks(newTasks);
    tasksChanged(newTasks);

    setEditTarget(null);
  };

  const onEditDataCanceled = () => {
    setEditTarget(null);
  };

  const onDeleteData = (task: Task, index: number) => {
    setDeleteTarget(task);
    setTargetIndex(index);
  };

  const confirmDeleteOK = async () => {
    const newTasks = tasks.filter((x) => x !== deleteTarget);
    setTasks(newTasks);
    tasksChanged(newTasks);
    setDeleteTarget(null);
  };

  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        <div className="h4">作業</div>
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              onAddData();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>

      <STable bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th className="col-md-2">作業種別</th>
            <th className="col-md-1">計画数量</th>
            <th className="col-md-1">作業数量</th>
            <th className="col-md-*">作業内容</th>
            <th className="col-md-1">事業者区分</th>
            <th className="col-md-1">事業者名称</th>
            <th className="col-md-1">地区</th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={`task_${index}`}>
              <TdCenter>{task.taskType.name}</TdCenter>
              <TdCenter className="text-end">{`${task.plannedAmount} ${task.unitOfTask}`}</TdCenter>
              <TdCenter className="text-end">{`${task.actualAmount} ${task.unitOfTask}`}</TdCenter>
              <TdCenter>
                <MultiLineText text={task.taskContent} />
              </TdCenter>
              <TdCenter>{task.contractType.name}</TdCenter>
              <TdCenter>{task.contractorName}</TdCenter>
              <TdCenter>
                <MultiLineText text={toAreaString(task.areas)} />{" "}
              </TdCenter>
              {!readonly && (
                <td className="text-center">
                  <RowEditButtons
                    editRequested={() => onEditData(task, index)}
                    deleteRequested={() => onDeleteData(task, index)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </STable>
      {editTarget && (
        <EditTaskDlg
          index={targetIndex}
          target={editTarget}
          metaData={meta}
          onOK={onEditDataCompleted}
          onCancel={onEditDataCanceled}
        />
      )}
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={[`${targetIndex + 1}番目の作業を削除しても良いですか`]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}
    </>
  );
};

const STable = styled(Table)`
  font-size: 12px;
`;

const TdCenter = styled.td`
  vertical-align: middle;
`;
