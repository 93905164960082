import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { getServerUrl, generateErrorMsg } from "utils/commonTools";

//======================================
// ユーザー管理
//======================================
export const useAuth = () => {
  const authCtxt = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage } = useContext(StatusContext);

  //--------------------------------------
  // ログイン処理
  //--------------------------------------
  const login = useCallback(
    async (username: string, password: string): Promise<{ succeeded: boolean; msg: string }> => {
      const config = {
        headers: { "Content-type": "application/json" },
      };
      try {
        setLoadingMessage("ログイン処理中");
        setIsLoading(true);
        const { data } = await axios.post(
          getServerUrl() + "user/login",
          { username, password },
          config
        );
        if (data.parks.length === 0) {
          return { succeeded: false, msg: "利用可能な公園がありません" };
        }
        authCtxt.login(data.token, new Date(data.expiresAt), data.displayName, data.parks);
        authCtxt.setEditWaitTime(data.editWaitTime);
        return { succeeded: true, msg: "" };
      } catch (error) {
        return { succeeded: false, msg: generateErrorMsg(error) };
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // パスワードの変更
  //--------------------------------------
  const changePassword = useCallback(
    async (
      oldPassword: string,
      newPassword: string
    ): Promise<{ succeeded: boolean; msg: string }> => {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authCtxt.token}`,
        },
      };
      try {
        const url = getServerUrl() + "user/password";
        const { data, status } = await axios.post(
          url,
          {
            oldPassword,
            newPassword,
          },
          config
        );
        authCtxt.updateToken(data.token, new Date(data.expiresAt));
        if (!data.succeeded) {
          const msg = "パスワードの更新に失敗しました";
          return { succeeded: false, msg };
        }

        switch (status) {
          case 200:
            return { succeeded: true, msg: "" };
          default:
            return { succeeded: false, msg: "不明なレスポンス:" + status };
        }
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return { login, changePassword };
};
