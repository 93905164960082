import { memo } from "react";
import styled from "styled-components";

const Footer = memo((props: { className?: string }) => {
  return (
    <footer className={`${props.className} `}>
      <div className="copyright text-center my-auto">
        <Sspan>2023- © Ecol System All rights reserved.</Sspan>
      </div>
    </footer>
  );
});

export default Footer;

const Sspan = styled.span`
  background-color: #fff8;
`;
