import { Table } from "react-bootstrap";
import styled from "styled-components";
import { IncidentDetail } from "../types/IncidentReport";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import MyCheckbox from "common/components/atoms/MyCheckbox";
import { IncidentTypes } from "../types/IncidetnTypes";

import { useAreas } from "common/hooks/useAreas";
import FunctionButton from "common/components/atoms/FunctionButton";
import { MultiLineText } from "common/components/atoms/MultiLineText";

type Props = {
  details: Array<IncidentDetail>;
  readonly: boolean;
  addDataRequested: () => void;
  editDataRequested: (data: IncidentDetail, index: number) => void;
  deleteDataRequested: (data: IncidentDetail) => void;
};

export const IncidentDetailTable = (props: Props) => {
  const { details, readonly, addDataRequested, editDataRequested, deleteDataRequested } = props;
  const { toAreaString } = useAreas();

  const toReportString = (detail: IncidentDetail) => {
    return detail.reportTypes.reduce<string>((prev, curr) => {
      const found = IncidentTypes.find((x) => x.id === curr.id);
      if (!found) return prev;
      if (prev.length > 0) return `${prev}, ${found.name}`;
      return found.name;
    }, "");
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        <h4>詳細</h4>
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addDataRequested();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>

      <Table bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th className="col-md-1" hidden={true}>
              整理番号
            </th>
            <th className="col-md-1">整理番号</th>
            <th className="col-md-1">通報</th>
            <th className="col-md-1">担当者</th>
            <th className="col-md-1">要因</th>
            <th className="col-md-*">内容</th>
            <th className="col-md-1">完了</th>
            <th className="col-md-2">地区</th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {details.map((x, index) => (
            <tr key={`inq_${index}`}>
              <TdCenter hidden={false}>{x.refNo}</TdCenter>
              <TdCenter>{toReportString(x)}</TdCenter>
              <TdCenter>{x.staff?.name}</TdCenter>
              <TdCenter>{x.factor?.name}</TdCenter>
              <TdLeft>
                <MultiLineText text={x.description} />
              </TdLeft>
              <TdCenter>
                <MyCheckbox initialValue={x.completed} readonly={true} />
              </TdCenter>
              <TdLeft>
                <MultiLineText text={toAreaString(x.areas)} />
              </TdLeft>

              {!readonly && (
                <td className="text-center">
                  <RowEditButtons
                    editRequested={() => {
                      editDataRequested(x, index);
                    }}
                    deleteRequested={() => {
                      deleteDataRequested(x);
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const TdLeft = styled.td`
  vertical-align: middle;
  text-align: start;
`;

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;
