import { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import MySwitch from "common/components/atoms/MySwitch";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { IncidentDetail } from "../types/IncidentReport";
import { IncidentMeta } from "../types/IncidentMeta";
import { DataEditTarget } from "common/types/DataEditTarget";
import { INCIDENT_TYPE, IncidentTypes } from "../types/IncidetnTypes";
import { NameIDList } from "common/components/molecules/NameIDList";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  target: DataEditTarget<IncidentDetail> | null;
  metaData: IncidentMeta;
  onOK: (data: IncidentDetail) => void;
  onCancel: () => void;
};

const CLEAR: string = "clear";

export const EditIncidentDlg = (props: Props) => {
  const { target, metaData, onOK, onCancel } = props;
  const [item, setItem] = useState<IncidentDetail | null>(null);

  useEffect(() => {
    setItem(target?.data ?? null);
  }, [target, metaData]);

  return (
    <Modal show={target !== null} size="lg">
      <Modal.Header>
        {target && target.index < 0 ? "事故通報の追加" : "事故通報の編集"}
      </Modal.Header>
      <Modal.Body>
        {item && (
          <Stack className="gap-3">
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                整理番号
              </Form.Label>
              <Col sm={6} md={4}>
                <Form.Control
                  type="text"
                  value={item.refNo}
                  onChange={(e) => {
                    e.preventDefault();
                    setItem({ ...item, refNo: e.currentTarget.value });
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={2}>
                通報
              </Form.Label>
              <Col sm={6} md={4}>
                <NameIDList
                  lists={IncidentTypes}
                  selecteds={item.reportTypes}
                  stringWhenEmpty="選択なし"
                  valueWhenEmpty={{ id: INCIDENT_TYPE.NO_CALL, name: "通報無し" }}
                  maxHeight="200px"
                  selectedChanged={(vals) => (item.reportTypes = vals)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={2}>
                受付者
              </Form.Label>
              <Col sm={6} md={4}>
                <Form.Select
                  aria-label="受付者"
                  value={item.staff?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const recipient = metaData.employees.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!recipient) {
                      setItem({ ...item, staff: undefined });
                      return;
                    }
                    setItem({ ...item, staff: recipient! });
                  }}
                >
                  <option value={CLEAR}>受付者を選択</option>
                  {metaData.employees.map((employee) => (
                    <option key={`emp_${employee.id}`} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={2}>
                要因
              </Form.Label>
              <Col sm={6} md={4}>
                <Form.Select
                  aria-label="要因"
                  value={item.factor?.id}
                  onChange={(e) => {
                    e.preventDefault();
                    const newValue = metaData.factors.find(
                      (x) => x.id.toString() === e.currentTarget.value
                    );
                    if (!newValue) setItem({ ...item, factor: undefined });
                    else setItem({ ...item, factor: newValue });
                  }}
                >
                  <option value={CLEAR}>要因を選択</option>
                  {metaData.factors.map((x) => (
                    <option key={`emp_${x.id}`} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={2}>
                内容
              </Form.Label>
              <Col md={10} sm={12}>
                <Form.Control
                  as="textarea"
                  value={item.description}
                  onChange={(e) => {
                    setItem({ ...item, description: e.target.value });
                  }}
                  style={{ height: "100px" }}
                />
              </Col>
            </Form.Group>

            <Stack direction="horizontal">
              <SFormLabel column sm={2}>
                完了
              </SFormLabel>
              <Col sm={1}>
                <MySwitch
                  initialValue={item.completed}
                  changed={(val) => {
                    setItem({ ...item, completed: val });
                  }}
                />
              </Col>
            </Stack>
            <Form.Group as={Row}>
              <SFormLabel column md={2}>
                地区
              </SFormLabel>
              <Col sm={9} md={9} lg={6}>
                {metaData.areas.length > 0 ? (
                  <NameIDList
                    lists={metaData.areas}
                    selecteds={item.areas ?? []}
                    stringWhenEmpty="選択なし"
                    maxHeight="200px"
                    selectedChanged={(vals) => (item.areas = vals)}
                  />
                ) : (
                  <div className="h6">地区の定義がありません</div>
                )}
              </Col>
            </Form.Group>
          </Stack>
        )}
      </Modal.Body>
      <Modal.Footer>
        {item && (
          <PrimaryButton
            className="mx-2"
            onClick={() => {
              onOK(item!);
            }}
            title="決定"
          />
        )}
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

const SFormLabel = styled(Form.Label)`
  text-align: left;
`;
