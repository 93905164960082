import { NameID } from "common/types/NameID";

export enum INCIDENT_TYPE {
  POLICE = 0,
  FIRE_DEP = 1,
  AMBULANCE = 2,
  NO_CALL = 3,
  TOTAL = -1,
}

export const IncidentTypes: NameID[] = [
  { id: INCIDENT_TYPE.POLICE, name: "警察" },
  { id: INCIDENT_TYPE.FIRE_DEP, name: "消防" },
  { id: INCIDENT_TYPE.AMBULANCE, name: "救急" },
];
