module.exports = {
  name: "gregorian_ja",
  months: [
    ["1月", "1月"],
    ["2月", "2月"],
    ["3月", "3月"],
    ["4月", "4月"],
    ["5月", "5月"],
    ["6月", "6月"],
    ["7月", "7月"],
    ["8月", "8月"],
    ["9月", "9月"],
    ["10月", "10月"],
    ["11月", "11月"],
    ["12月", "12月"],
  ],
  weekDays: [
    ["土曜日", "土"],
    ["日曜日", "日"],
    ["月曜日", "月"],
    ["火曜日", "火"],
    ["水曜日", "水"],
    ["木曜日", "木"],
    ["金曜日", "金"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["午前", "am"],
    ["午後", "pm"],
  ],
};
