import { NameID } from "common/types/NameID";
import { ParkEvent, Participant } from "../types/ParkEventData";
import { EVENT_TYPE } from "../types/EVENT_TYPE";

export const useParkEventData = () => {
  //--------------------------------------
  // 空の有料施設データを作成する
  //--------------------------------------
  const createEmptyParkEvent = (eventType: EVENT_TYPE, participants?: NameID[]): ParkEvent => {
    return {
      title: "",
      startTime: "",
      endTime: "",
      location: "",
      description: "",
      participants:
        participants?.map((x) => {
          return {
            nameID: x,
            count: 0,
          };
        }) ?? [],
      report: "",
      note: "",
      revenueReport:
        eventType === EVENT_TYPE.Independent
          ? {
              isFree: false,
              fee: 0,
              numPaid: 0,
              honorarium: 0,
              materialCosts: 0,
              settlementAmount: 0,
            }
          : undefined,
      pictures: [],
    };
  };

  //--------------------------------------
  // 参加人数の種別が後に変更された場合に対応
  //--------------------------------------
  const resolveParticipants = (parkEvent: ParkEvent, participants: NameID[]): ParkEvent => {
    let updated = { ...parkEvent };
    // メタデータに存在しないが記録がある
    updated.participants.forEach((x) => {
      if (!participants.find((y) => x.nameID.id === y.id)) x.deleted = true;
    });

    // 保存後にメタデータに追加された
    const dataToAdd = participants.filter(
      (x) => !updated.participants.find((y) => y.nameID.id === x.id)
    );
    if (dataToAdd) {
      const additionalParticipants = dataToAdd.map((x) => ({ nameID: x, count: 0 } as Participant));
      return { ...updated, participants: [...updated.participants, ...additionalParticipants] };
    }
    return { ...updated };
  };

  return { createEmptyParkEvent, resolveParticipants };
};
