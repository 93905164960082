export const useGetPrint = () => {
  //--------------------------------------
  // ブラウザのダウンロードをキックする
  //--------------------------------------
  const initiateDownload = (url: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  return { initiateDownload };
};
