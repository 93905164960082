/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext } from "react";

import AuthContext from "common/store/AuthContext";
import {
  generateErrorMsg,
  getServerUrl,
  genApiDatePath,
  generateSaveResult,
} from "utils/commonTools";

import { PostBasicResponse, PostResponse } from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { SpecialNoteReport } from "../types/SpecialNotesReport";
import axios from "axios";
import { REPORT_TYPE } from "common/types/consts/Defines";

export const useSpecialNotes = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      date: Date
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: SpecialNoteReport;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(
          getServerUrl() + `specialnotes/report/${parkId}/${reportType}/${genApiDatePath(date)}`,
          config
        );
        const responseData = data as PostBasicResponse<SpecialNoteReport>;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));
        return {
          succeeded: true,
          msg: "",
          data: responseData.data,
          lastUpdated: responseData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  //--------------------------------------
  // 日報の登録処理
  //--------------------------------------
  const saveReportData = useCallback(
    async (
      parkId: number,
      reportType: REPORT_TYPE,
      date: Date,
      report: SpecialNoteReport,
      title: string
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data, status } = await axios.post(
          getServerUrl() + `specialnotes/report/${parkId}/${reportType}/${genApiDatePath(date)}`,
          { files: report.files, pictures: report.pictures, Comment: report.comment },
          config
        );
        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult(title, status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  return { getReportData, saveReportData };
};
