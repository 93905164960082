/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { VisitorData } from "../types/VisitorData";
import { VisitorReport } from "../types/VisitorReport";
import { VisitMeta } from "../types/VisitortsMetaData";
import { NameID } from "common/types/NameID";

export const useVisitorData = () => {
  //--------------------------------------
  // 来園者情報を作成する
  //--------------------------------------
  const createData = useCallback((visitor: VisitMeta, periods: NameID[]) => {
    const retVal = {
      id: visitor.id,
      name: visitor.name,
      counts: periods.map((x) => ({ period: x, count: 0 })),
      total: 0,
    } as VisitorData;

    return retVal;
  }, []);

  //--------------------------------------
  // 来園数データに対応する来園数を表示中のリストから取り出し更新する
  // いなければ今はないデータなので、一時的に追加する
  //--------------------------------------
  const updateData = useCallback((lstData: VisitorData[], reported: VisitorData[]) => {
    reported.forEach((target) => {
      const found = lstData.find((data) => data.id === target.id);
      if (found) {
        found.counts.forEach(
          (x) => (x.count = target.counts.find((y) => y.period.id === x.period.id)?.count ?? 0)
        );
        found.total = target.counts.reduce((prev, next) => prev + next.count, 0);
      } else {
        lstData.push({ ...target, inactive: true });
      }
    });
  }, []);

  //--------------------------------------
  // 利用者情報をリセットする
  //--------------------------------------
  const resetData = useCallback((data: VisitorData) => {
    data.counts.forEach((x) => (x.count = 0));
    data.total = 0;
  }, []);

  //--------------------------------------
  // 日付が変わったりした時にデータの初期化を行う
  //--------------------------------------
  const clearData = useCallback((baseData: VisitorReport) => {
    // あり得ない
    if (!baseData) return;

    // チェックを全てオフに
    baseData.places?.forEach((data) => resetData(data));
    baseData.methods?.forEach((data) => resetData(data));
    baseData.others?.forEach((data) => resetData(data));

    // 削除済みのデータが入っている場合取り除く
    baseData.places = baseData.places?.filter((data) => !data.inactive);
    baseData.methods = baseData.methods?.filter((data) => !data.inactive);
    baseData.others = baseData.others?.filter((data) => !data.inactive);

    // データがないときに未定義にする
    if (baseData.places?.length === 0) {
      baseData.places = undefined;
    }
    if (baseData.methods?.length === 0) {
      baseData.methods = undefined;
    }
    if (baseData.others?.length === 0) {
      baseData.others = undefined;
    }

    return {
      places: baseData.places,
      methods: baseData.methods,
      others: baseData.others,
    };
  }, []);

  //--------------------------------------
  // データAからデータBへデータを適用する
  //--------------------------------------
  const applyData = (dataFrom: VisitorReport, dataTo: VisitorReport) => {
    // 取得したデータを反映
    const existingData = dataFrom;
    if (existingData) {
      if (existingData.places && existingData.places?.length > 0) {
        if (!dataTo.places) dataTo.places = [];
        updateData(dataTo.places!, existingData.places);
      }
      if (existingData.methods && existingData.methods?.length > 0) {
        if (!dataTo!.methods) {
          dataTo.methods = [];
        }
        updateData(dataTo.methods!, existingData.methods);
      }
      if (existingData.others && existingData.others?.length > 0) {
        if (!dataTo!.others) {
          dataTo.others = [];
        }
        updateData(dataTo.others!, existingData.others);
      }
    }
    return dataTo;
  };

  return {
    createData,
    updateData,
    resetData,
    clearData,
    applyData,
  };
};
