import { useEffect, useState } from "react";
import { Col, Table } from "react-bootstrap";

import styled from "styled-components";

import { ShowEmpty } from "common/components/atoms/ShowEmpty";

import { toYearMonth } from "utils/DateTools";

import { DetailsByMonth } from "../types/AggregateData";
import { NameID } from "common/types/NameID";

//=====================================
// 月ごとの詳細をテーブル表示する
//=====================================
export const ByMonthTable = (props: { title: string; data: DetailsByMonth[]; height: number }) => {
  const [data, setData] = useState<DetailsByMonth[]>([]);
  
  // 利用場所の一覧を取得・生成する
  const extractPlaces = () => {
    // 利用場所の一覧を生成
    const placeSet = new Map<number, NameID>();
    data.forEach((x) => {
      x.prevYear.data.forEach((y) => placeSet.set(y.id, { id: y.id, name: y.name }));
      x.thisYear.data.forEach((y) => placeSet.set(y.id, { id: y.id, name: y.name }));
    });
    return Array.from(placeSet.values());
  };

  const extractMonths = () => {
    return data.map((x) => x.month);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const createValueCells = (month: number, placeId: number) => {
    const today = new Date();
    const thisMonth = today.getMonth() + 1;
    const yearMonth = toYearMonth(today.getFullYear(), thisMonth);
    let valPrevYear = 0;
    let valThisYear: string | number = 0;

    const target = data.find((x) => x.month === month);
    if (target) {
      // 昨年度のデータ
      valPrevYear = target.prevYear.data.find((y) => y.id === placeId)?.value ?? 0;
      // 今年度のデータ
      if (toYearMonth(target.thisYear.year, month) <= yearMonth) {
        valThisYear = target.thisYear.data.find((y) => y.id === placeId)?.value ?? 0;
      } else {
        // 未来
        valThisYear = "-";
      }
    }
    return [
      <SValCell key={`val1_${month}_${placeId}`}>{valPrevYear}</SValCell>,
      <SValCell key={`val2_${month}_${placeId}`}>{valThisYear}</SValCell>,
    ];
  };

  const createTotalCells = (month: number) => {
    const target = data.find((x) => x.month === month);
    return [
      <SValCell key={`sum1_${month}`}>
        {target?.prevYear.data.reduce((prev, current) => (prev += current.value), 0)}
      </SValCell>,
      <SValCell key={`sum2_${month}`}>
        {target?.thisYear.data.reduce((prev, current) => (prev += current.value), 0)}
      </SValCell>,
    ];
  };

  return (
    <Col className="mt-2 mx-2 agg-table-border">
      <div className="h5"> {props.title} </div>
      {data.length === 0 ? (
        <ShowEmpty>設定がありません</ShowEmpty>
      ) : (
        <Table bordered hover responsive className="summary-table">
          {/* ヘッダーは1行目が月、2行目が前年、今年のペア */}
          <thead>
            <tr>
              <th rowSpan={2} className="col-*" style={{ minWidth: "100px" }}>
                項目
              </th>
              {data.map((x) => (
                <th colSpan={2} key={`month_${x.month}`} style={{ textAlign: "center" }}>
                  {x.month}月
                </th>
              ))}
            </tr>
            <tr>
              {data.map((x) =>
                [
                  <th key={`year_${x.prevYear.year}_${x.month}`}>{x.prevYear.year}</th>,
                  <th key={`year_${x.thisYear.year}_${x.month}`}>{x.thisYear.year}</th>,
                ].map((y) => y)
              )}
            </tr>
          </thead>
          <tbody>
            {extractPlaces().map((x) => (
              <tr key={`place_${x.id}`}>
                <td>{x.name}</td>
                {extractMonths().map((month) => createValueCells(month, x.id).map((y) => y))}
              </tr>
            ))}
            <tr className="row-total">
              <td>合計</td>
              {extractMonths().map((month) => createTotalCells(month).map((y) => y))}
            </tr>
          </tbody>
        </Table>
      )}
    </Col>
  );
};

const SValCell = styled.td`
  text-align: right;
`;
