import { InquiryData, InquirySummary } from "../types/InquiryInfo";
import { InquiryCategory } from "../types/InquiryMeta";

export const useInquiryData = () => {
  //--------------------------------------
  // 空の問合せ苦情要望データを作成する
  //--------------------------------------
  const createEmptyInquiryData = (): InquiryData => {
    return {
      inquiryNo: "",
      category: undefined,
      subCategory: undefined,
      recipient: undefined,
      method: undefined,
      contents: "",
      action: "",
      numIncident: 1,
      completed: false,
      reported: false,
    };
  };

  //--------------------------------------
  // カテゴリ一覧から空のサマリーデータを作成する
  // サマリーには合計の行が追加される
  //--------------------------------------
  const createEmptySummaries = (categories: InquiryCategory[]) => {
    const categoryWithTotal: InquiryCategory[] = [...categories];
    categoryWithTotal.push({ category: { id: -1, name: "合計" } });
    const emptySumamries: InquirySummary[] = categoryWithTotal.map((x) => ({
      typeID: x.category.id,
      typeName: x.category.name,
      visit: 0,
      phone: 0,
      email: 0,
      others: 0,
      total: 0,
    }));
    return emptySumamries;
  };

  const calcSum = (details: InquiryData[], typeId: number, methodId: number) => {
    return details
      .map((x) => (x.category?.id === typeId && x.method?.id === methodId ? x.numIncident : 0))
      .reduce((prev, current) => prev + current, 0);
  };
  const TOTAL = -1;
  const PHONE = 0;
  const VISIT = 1;
  const EMAIL = 3;
  const OTHERS = 2;
  //--------------------------------------
  // サマリーの再計算を行う
  //--------------------------------------
  const updateSummaries = (summaries: InquirySummary[], details: InquiryData[]) => {
    var total = summaries.find((x) => x.typeID === TOTAL)!;
    total.visit = 0;
    total.phone = 0;
    total.email = 0;
    total.others = 0;
    total.total = 0;

    summaries.forEach((cat) => {
      if (cat.typeID >= 0) {
        cat.visit = calcSum(details, cat.typeID, VISIT);
        total.visit += cat.visit;
        cat.phone = calcSum(details, cat.typeID, PHONE);
        total.phone += cat.phone;
        cat.email = calcSum(details, cat.typeID, EMAIL);
        total.email += cat.email;
        cat.others = calcSum(details, cat.typeID, OTHERS);
        total.others += cat.others;
        cat.total = cat.visit + cat.phone + cat.email + cat.others;
        total.total += cat.total;
      }
    });

    return [...summaries];
  };

  return { createEmptyInquiryData, createEmptySummaries, updateSummaries };
};
