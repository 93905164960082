import MyCheckbox from "common/components/atoms/MyCheckbox";
import { RevenueReport } from "../types/ParkEventData";
import { useState } from "react";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import { Table } from "react-bootstrap";
import { STEP_YEN } from "common/types/consts/Defines";

type Props = {
  revenue: RevenueReport;
  readonly: boolean;
  updated?: () => void;
};

//=====================================
// 事業の収益情報を入力するパネル
//=====================================
export const RevenueReportPanel = ({ revenue, readonly, updated }: Props) => {
  const [isFree, setIsFree] = useState(revenue.isFree);
  return (
    <>
      {(!readonly || isFree) && (
        <MyCheckbox
          initialValue={isFree}
          changed={(val) => {
            revenue.isFree = val;
            setIsFree(val);
            updated?.();
          }}
          readonly={readonly}
          label="無料"
          className="mt-2"
          borderAlways={true}
        />
      )}
      {!isFree && (
        <Table bordered hover style={{ width: "300px" }}>
          <tbody>
            <tr>
              <td>合計参加費</td>
              <td>
                <MyNumberInput
                  initialValue={revenue.fee}
                  step={STEP_YEN}
                  changed={(value) => {
                    revenue.fee = value;
                    updated?.();
                  }}
                  readonly={readonly}
                />
              </td>
              <td>円</td>
            </tr>
            <tr>
              <td>参加費徴収人数</td>
              <td>
                <MyNumberInput
                  initialValue={revenue.numPaid}
                  changed={(value) => {
                    revenue.numPaid = value;
                    updated?.();
                  }}
                  readonly={readonly}
                />
              </td>
              <td>人</td>
            </tr>
            <tr>
              <td>講師謝礼</td>
              <td>
                <MyNumberInput
                  initialValue={revenue.honorarium}
                  step={STEP_YEN}
                  changed={(value) => {
                    revenue.honorarium = value;
                    updated?.();
                  }}
                  readonly={readonly}
                />
              </td>
              <td>円</td>
            </tr>
            <tr>
              <td>材料費</td>
              <td>
                <MyNumberInput
                  initialValue={revenue.materialCosts}
                  step={STEP_YEN}
                  changed={(value) => {
                    revenue.materialCosts = value;
                    updated?.();
                  }}
                  readonly={readonly}
                />
              </td>
              <td>円</td>
            </tr>
            <tr>
              <td>決算額</td>
              <td>
                <MyNumberInput
                  initialValue={revenue.settlementAmount}
                  step={STEP_YEN}
                  changed={(value) => {
                    revenue.settlementAmount = value;
                    updated?.();
                  }}
                  readonly={readonly}
                />
              </td>
              <td>円</td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};
