import { Col, Row, Table } from "react-bootstrap";

import { PayFacilityData, PayFacilitySummary } from "../types/PayFacilityData";
import { PayFacilitiesMeta } from "../types/PayFacilityMeta";

type Props = {
  MetaData: PayFacilitiesMeta | null; // 支払方法ごとの情報はsummaryに含まれていない
  Summaries: PayFacilitySummary[] | null;
  Details: PayFacilityData[] | null;
};

export const PayFacilitySummaryTable = ({ Summaries, MetaData, Details }: Props) => {
  if (!MetaData) return <div className="h5">メタデータがありません</div>;

  //--------------------------------------
  // 施設の支払種別ごとの合計はSummaryに含まれていないので個別に計算をする
  //--------------------------------------
  const calcTotal = (facilityId: number, paymentMethodId: number) => {
    if (!Details) return 0;
    // 合計行の場合は支払種別で全部足す
    if (facilityId < 0) {
      return Details.reduce((prevVal: number, currVal): number => {
        return (
          prevVal +
          currVal.usages.reduce((sum: number, key): number => {
            return sum + (key.paymentType?.id === paymentMethodId ? key.fee : 0);
          }, 0)
        );
      }, 0);
    }

    // 施設の場合
    return Details.find((x) => x.facility.id === facilityId)?.usages.reduce(
      (prevVal: number, currVal): number => {
        return prevVal + (currVal.paymentType?.id === paymentMethodId ? currVal.fee : 0);
      },
      0
    );
  };

  return (
    <>
      <h4>合計</h4>
      <Row>
        <Col xs={12} lg={8}>
          <Table bordered hover responsive className="summary-table">
            <thead>
              <tr className="text-center">
                <th className="col-md-2">施設名</th>
                <th className="col-md-1">利用件数</th>
                <th className="col-md-1">利用者数</th>
                {MetaData.paymentMethods?.map((method) => {
                  return (
                    <th className="col-md-1" key={`payfacth_${method.id}`}>
                      {method.name}
                    </th>
                  );
                })}
                <th className="col-md-1">合計</th>
              </tr>
            </thead>
            <tbody>
              {Summaries &&
                Summaries.map((x) => {
                  return (
                    <tr
                      key={`facrow_${x.facility.id}`}
                      className={`text-end ${x.facility.id < 0 && "row-total"}`}
                    >
                      <td className="text-start">{x.facility.name}</td>
                      <td>{x.count.toLocaleString()}</td>
                      <td>{x.numUser.toLocaleString()}</td>
                      {MetaData.paymentMethods?.map((method) => {
                        return (
                          <td key={`payfac_${x.facility.id}_${method.id}`}>
                            &yen;{calcTotal(x.facility.id, method.id)?.toLocaleString()}
                          </td>
                        );
                      })}
                      <td>&yen;{x.fee.toLocaleString()}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
