import { createContext, useState, useEffect, useCallback, ReactNode } from "react";
import { ParkInfo } from "common/types/ParkInfo";

let logoutTimer: number | null = null;

type AuthContextType = {
  token: string | null;
  expiresAt: Date | null;
  userName: string | null;
  parks: Array<ParkInfo> | null;
  isLoggedIn: boolean;
  selectedPark: ParkInfo | null;
  readOnly: boolean;
  login: (token: string, expiresAt: Date, displayName: string, parks: Array<ParkInfo>) => void;
  updateToken: (token: string, expirationDate: Date) => void;
  selectPark: (park: ParkInfo | null) => void;
  logout: () => void;
  editWaitTime: number;
  setEditWaitTime: (value: number) => void;
};

const AuthContext = createContext({} as AuthContextType);
export default AuthContext;

const calculateRemainingTime = (expirationDate: Date) => {
  const currentTime = new Date().getTime();
  const adjexpirationDate = new Date(expirationDate).getTime();

  const remainingDuration = adjexpirationDate - currentTime;

  return remainingDuration;
};

const clearStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("displayName");
  localStorage.removeItem("expiresAt");
  localStorage.removeItem("parks");
  localStorage.removeItem("park");
};

type TokenData = {
  token: string | null;
  displayName: string | null;
  expiresAt: Date | null;
  parks: Array<ParkInfo> | null;
  selectedPark: ParkInfo | null;
};

const retrieveStoredToken = (): TokenData | null => {
  const storedToken = localStorage.getItem("token");
  const storedName = localStorage.getItem("displayName");
  const storedExpiresAt = localStorage.getItem("expiresAt");
  const storedParks = localStorage.getItem("parks");
  const storedPark = localStorage.getItem("park");

  // 選択公園以外は保存されていないと整合性が取れない
  if (!storedToken || !storedName || !storedExpiresAt || !storedParks) {
    clearStorage();

    return null;
  }

  let selectedPark;
  if (storedPark) selectedPark = JSON.parse(storedPark);

  const expirationDate = storedExpiresAt ? new Date(storedExpiresAt) : null;
  // 残り時間が少ない場合は再ログインさせる
  if (expirationDate) {
    if (calculateRemainingTime(expirationDate) <= 3600) {
      clearStorage();
      return null;
    }
  }

  return {
    token: storedToken,
    displayName: storedName,
    expiresAt: expirationDate,
    parks: JSON.parse(storedParks),
    selectedPark,
  };
};

export const AuthContextProvider = (props: { children: ReactNode }) => {
  // ---------------------------------
  // 以前のログイン情報を復元
  // ---------------------------------
  const savedData = retrieveStoredToken();

  const [token, setToken] = useState(savedData?.token ?? null);
  const [userName, setUserName] = useState(savedData?.displayName ?? null);
  const [expiresAt, setExpiresAt] = useState(savedData?.expiresAt ?? null);
  const [parks, setParks] = useState(savedData?.parks ?? null);
  const [selectedPark, setSelectedPark] = useState(savedData?.selectedPark ?? null);
  const [readOnly, setReadOnly] = useState(false);
  const [editWaitTime, setEditWaitTime] = useState(10000);

  const userIsLoggedIn = !!token;

  // ---------------------------------
  // ログイン結果のデータを格納する(サーバー側の処理結果)
  // ---------------------------------
  const loginHandler = (
    token: string,
    expiresAt: Date,
    displayName: string,
    parks: Array<ParkInfo>
  ) => {
    setToken(token);
    setExpiresAt(expiresAt);
    setUserName(displayName);
    setParks(parks);
    localStorage.setItem("token", token);
    localStorage.setItem("expiresAt", expiresAt.toISOString());
    localStorage.setItem("displayName", displayName);
    localStorage.setItem("parks", JSON.stringify(parks));

    startTimer(expiresAt);
  };

  // ---------------------------------
  // ログアウト処理を行う(クライアント側の処理)
  // ---------------------------------
  const logoutHandler = useCallback(() => {
    setToken(null);
    setExpiresAt(null);
    setUserName(null);
    setSelectedPark(null);

    clearStorage();

    if (logoutTimer) {
      clearTimeout(logoutTimer);
      logoutTimer = null;
    }
  }, []);

  // ---------------------------------
  // トークンの更新を行う
  // スライディングトークンなので、処理を実行するたびに新しい有効期間のトークンが発行される
  // ---------------------------------
  const updateTokenHandler = (token: string, expiresAt: Date) => {
    setToken(token);
    setExpiresAt(expiresAt);

    localStorage.setItem("token", token);
    localStorage.setItem("expiresAt", expiresAt.toISOString());

    if (logoutTimer) {
      clearTimeout(logoutTimer);
      logoutTimer = null;
    }

    startTimer(expiresAt);
  };

  // ---------------------------------
  // トークンの期限切れまえに自動ログアウトするためのタイマー起動
  // ---------------------------------
  const startTimer = useCallback(
    (expiresAt: Date) => {
      const remainingTime = calculateRemainingTime(expiresAt);
      if (logoutTimer) {
        clearTimeout(logoutTimer);
        logoutTimer = null;
      }

      logoutTimer = window.setTimeout(logoutHandler, remainingTime);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // ---------------------------------
  // 公園の選択処理
  // ---------------------------------
  const selectParkHandler = (park: ParkInfo | null) => {
    setSelectedPark(park);
    localStorage.setItem("park", JSON.stringify(park));

    setReadOnly(!park?.canWrite ?? true);
  };

  useEffect(() => {
    if (expiresAt) {
      startTimer(expiresAt);
    }

    return () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = {
    token,
    expiresAt,
    userName,
    parks,
    selectedPark,
    isLoggedIn: userIsLoggedIn,
    readOnly,
    login: loginHandler,
    updateToken: updateTokenHandler,
    selectPark: selectParkHandler,
    logout: logoutHandler,

    editWaitTime,
    setEditWaitTime,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};
