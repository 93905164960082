import { useContext } from "react";
import axios, { AxiosRequestConfig, HttpStatusCode } from "axios";
import AuthContext from "common/store/AuthContext";

import { genApiMonthPath, generateErrorMsg, getServerUrl } from "utils/commonTools";
import { useGetPrint } from "./useGetPrint";
import { getPrintResult } from "../types/getPrintResult";

//--------------------------------------
// 月報の印刷
//--------------------------------------
export const useMonthlyReport = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  const { initiateDownload } = useGetPrint();

  const getMonthlyReort = async (date: Date, title: string): Promise<getPrintResult> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      responseType: "blob",
    } as AxiosRequestConfig;

    const parkId = authCtxt.selectedPark?.parkId;
    const parkName = authCtxt.selectedPark?.parkName;

    try {
      const response = await axios.get(
        getServerUrl() + `print/monthly/${parkId}/${genApiMonthPath(date)}`,
        config
      );
      if (response.status === HttpStatusCode.NoContent) {
        return {
          succeeded: false,
          msg: "レポートを生成できませんでした（帳票が未登録です）",
        };
      }
      const url = window.URL.createObjectURL(response.data);

      const fileName = `${title}_${parkName}_${date.getFullYear()}${String(
        date.getMonth() + 1
      ).padStart(2, "0")}.xlsx`;
      initiateDownload(url, fileName);
      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  return { getMonthlyReort };
};
