import { memo, useRef } from "react";
import { Form, FloatingLabel } from "react-bootstrap";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import FormContainer from "common/components/templates/FormContainer";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  changePasswordRequested: (oldPassword: string, newPassword: string) => void;
  showErrorRequested: (msg: string) => void;
  onCancel: () => void;
};

const MAX_PASSWORD_LENGTH = 24;

export const ChangePasswordPanel = memo((props: Props) => {
  const formOldPassword = useRef<HTMLInputElement>(null);
  const formNewPassword = useRef<HTMLInputElement>(null);
  const formNewPasswordConfirm = useRef<HTMLInputElement>(null);
  //--------------------------------------
  // パスワード変更ボタンの処理
  //--------------------------------------
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit();
  };

  const onSubmit = async () => {
    props.showErrorRequested("");

    var newPassword = formNewPassword.current?.value;

    if (!newPassword || newPassword.length === 0) {
      props.showErrorRequested("新しいパスワードを入力してください");
      return;
    }
    if (newPassword.length > 24) {
      props.showErrorRequested("新しいパスワードが24文字を超えています");
      return;
    }
    if (newPassword !== formNewPasswordConfirm.current?.value) {
      props.showErrorRequested("新しいパスワードが一致しません");
      return;
    }

    props.changePasswordRequested(formOldPassword.current?.value || "", newPassword);
  };

  return (
    <FormContainer size="small">
      <div className="card shadow mb-4 mt-2 px-3 py-3">
        <h2 className="my-3">パスワード変更</h2>
        <Form onSubmit={submitHandler}>
          <FloatingLabel className="mb-4" controlId="formOldPassword" label="今のパスワード">
            <Form.Control
              type="password"
              maxLength={MAX_PASSWORD_LENGTH}
              placeholder="今のパスワードを入力してください"
              ref={formOldPassword}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-3"
            controlId="formNewPassword"
            label="新しいパスワード（最大24文字）"
          >
            <Form.Control
              type="password"
              placeholder="新しいパスワードを入力してください（２４文字まで）"
              ref={formNewPassword}
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-3"
            controlId="formNewPasswordConfirm"
            label="新しいパスワード(確認)"
          >
            <Form.Control
              type="password"
              placeholder="パスワードを入力してください"
              ref={formNewPasswordConfirm}
            />
          </FloatingLabel>
          <div className="mt-4" style={{ display: "flex", justifyContent: "right" }}>
            <PrimaryButton title="実行" onClick={onSubmit} className="me-1" />
            <CancelButton
              onClick={(e) => {
                e.preventDefault();
                props.onCancel();
              }}
            />
          </div>
        </Form>
      </div>
    </FormContainer>
  );
});
