import { useEffect, useState } from "react";
import { Form, Stack } from "react-bootstrap";

import { ChartInfo } from "../types/ChartInfo";

import FunctionButton from "common/components/atoms/FunctionButton";
import { NameID } from "common/types/NameID";

type Props = {
  chart: ChartInfo;
  candidates: NameID[];
  updated: (newVal: ChartInfo) => void;
  deleteRequested: () => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  moveUp: () => void;
  moveDown: () => void;
};
export const SelectGraphRow = (props: Props) => {
  const [chart, setChart] = useState<ChartInfo>();

  useEffect(() => {
    setChart(props.chart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return chart ? (
    <tr>
      <td>
        <Form.Select
          aria-label="グラフ・表"
          value={chart.chartId}
          onChange={(e) => {
            e.preventDefault();
            const newChart = { ...chart };
            newChart.chartId = +e.currentTarget.value;
            setChart(newChart);
            props.updated(newChart);
          }}
        >
          <option value={-1}>未選択</option>
          {props.candidates.map((item) => (
            <option key={`listItem_${item.id}`} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      </td>
      <td>
        <Form.Select
          aria-label="横"
          value={chart.numCols}
          onChange={(e) => {
            e.preventDefault();
            const newChart = { ...chart };
            newChart.numCols = +e.currentTarget.value;
            setChart(newChart);
            props.updated(newChart);
          }}
        >
          {[...Array(2)].map((_, i) => (
            <option value={i + 1} key={`cols_${i}`}>
              {" "}
              {i + 1}{" "}
            </option>
          ))}
        </Form.Select>
      </td>
      <td>
        <Form.Select
          aria-label="縦"
          value={chart.numRows}
          onChange={(e) => {
            e.preventDefault();
            const newChart = { ...chart };
            newChart.numRows = +e.currentTarget.value;
            setChart(newChart);
            props.updated(newChart);
          }}
        >
          {[...Array(2)].map((_, i) => (
            <option value={i + 1} key={`rows_${i}`}>
              {" "}
              {i + 1}{" "}
            </option>
          ))}
        </Form.Select>
      </td>
      <td>
        <Stack direction="horizontal">
          <FunctionButton
            disabled={!props.canMoveUp}
            size="sm"
            buttonType="edit"
            tooltip="一つ上へ"
            onClick={(e) => {
              e.preventDefault();
              props.moveUp();
            }}
          >
            <i className="fas fa-caret-up" />
          </FunctionButton>
          <FunctionButton
            disabled={!props.canMoveDown}
            size="sm"
            buttonType="edit"
            className="ms-1"
            tooltip="一つ下へ"
            onClick={(e) => {
              e.preventDefault();
              props.moveDown();
            }}
          >
            <i className="fas fa-caret-down" />
          </FunctionButton>
          <FunctionButton
            size="sm"
            buttonType="delete"
            className="mx-auto"
            tooltip="削除"
            onClick={(e) => {
              e.preventDefault();
              props.deleteRequested();
            }}
          >
            {<i className="fas fa-trash-alt" />}
          </FunctionButton>
        </Stack>
      </td>
    </tr>
  ) : (
    <></>
  );
};
