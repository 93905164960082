import { useContext } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import AuthContext from "common/store/AuthContext";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

import LoginPage from "pages/user/LoginPage";
import SelectParkPage from "pages/user/SelectParkPage";
import ChangePasswordPage from "pages/user/ChangePasswordPage";
import HomePage from "pages/home/HomePage";
import ParkStaffsPage from "pages/parkstaffs/ParkStaffsPage";
import ParkVisitorsPage from "pages/visitors/ParkVisitorsPage";
import InquiryPage from "pages/inquiry/InquiryPage";
import ExclusiveUsesPage from "pages/exclusiveuses/ExclusiveUsesPage";
import SpecialNotesPage from "pages/specialnotes/SpecialNotesPage";
import PayFacilityPage from "pages/payfacility/PayFacilityPage";
import { WorkReportPage } from "pages/workreport/WorkReportPage";
import { IndependentEventPage } from "pages/parkevents/IndependentEventPage";
import { CollaborateEventPage } from "pages/parkevents/CollaborateEventPage";
import IncidentPage from "pages/incidents/IncidentPage";
import { PrintTemplatePage } from "pages/print/PrintTemplatePage";
import { PrintPage } from "pages/print/PrintPage";
import CommentsPage from "pages/comments/CommentsPage";
import { PeriodEventPage } from "pages/periodEvent/PeriodEventPage";

function App() {
  const { selectedPark, isLoggedIn } = useContext(AuthContext);

  const getPageTitle = (tabID: number, reportType: REPORT_TYPE) => {
    if (reportType === REPORT_TYPE.WORK)
      return selectedPark?.workTabs?.find((x) => x.id === tabID)?.name ?? "タブ名不明";

    return selectedPark?.dailyTabs?.find((x) => x.id === tabID)?.name ?? "タブ名不明";
  };

  return (
    <BrowserRouter>
      <div>
        {!isLoggedIn ? (
          <LoginPage />
        ) : (
          <Routes>
            <Route path="/password" element={<ChangePasswordPage />} />
            {!selectedPark ? (
              <Route path="*" element={<SelectParkPage />} />
            ) : (
              <>
                <Route path="/" element={<HomePage pageTitle="ホーム" />} />
                <Route path="/print" element={<PrintPage pageTitle="出力" />} />
                <Route
                  path="/parkstaffs/daily"
                  element={
                    <ParkStaffsPage
                      pageTitle={getPageTitle(TAB_ID.STAFFS, REPORT_TYPE.DAILY)}
                      reportType={REPORT_TYPE.DAILY}
                    />
                  }
                />
                <Route
                  path="/parkstaffs/work"
                  element={
                    <ParkStaffsPage
                      pageTitle={getPageTitle(TAB_ID.STAFFS, REPORT_TYPE.WORK)}
                      reportType={REPORT_TYPE.WORK}
                    />
                  }
                />
                <Route
                  path="/visitors"
                  element={
                    <ParkVisitorsPage
                      pageTitle={getPageTitle(TAB_ID.VISITORS, REPORT_TYPE.DAILY)}
                    />
                  }
                />
                <Route
                  path="/inquiry"
                  element={
                    <InquiryPage pageTitle={getPageTitle(TAB_ID.INQUIRY, REPORT_TYPE.DAILY)} />
                  }
                />
                <Route
                  path="/incidents"
                  element={
                    <IncidentPage pageTitle={getPageTitle(TAB_ID.INCIDENTS, REPORT_TYPE.DAILY)} />
                  }
                />
                <Route
                  path="/exclusiveuses"
                  element={
                    <ExclusiveUsesPage
                      pageTitle={getPageTitle(TAB_ID.EXCLUSIVE_USE, REPORT_TYPE.DAILY)}
                    />
                  }
                />
                <Route
                  path="/payfacility"
                  element={
                    <PayFacilityPage
                      pageTitle={getPageTitle(TAB_ID.PAY_FACILITY, REPORT_TYPE.DAILY)}
                    />
                  }
                />
                <Route path="/parkEvents">
                  <Route
                    path="independent/:id?"
                    element={
                      <IndependentEventPage
                        pageTitle={getPageTitle(TAB_ID.PRIVATE_BIZ, REPORT_TYPE.DAILY)}
                      />
                    }
                  />
                  <Route
                    path="collaborate/:id?"
                    element={
                      <CollaborateEventPage
                        pageTitle={getPageTitle(TAB_ID.PUBLIC_BIZ, REPORT_TYPE.DAILY)}
                      />
                    }
                  />
                  <Route
                    path="periodical"
                    element={
                      <PeriodEventPage
                        pageTitle={getPageTitle(TAB_ID.PERIODICAL_EVENT, REPORT_TYPE.DAILY)}
                      />
                    }
                  />
                </Route>
                <Route path="/specialnotes">
                  <Route
                    path="daily"
                    element={
                      <SpecialNotesPage
                        pageTitle={getPageTitle(TAB_ID.SPECIAL_NOTE, REPORT_TYPE.DAILY)}
                        reportType={REPORT_TYPE.DAILY}
                      />
                    }
                  />
                  <Route
                    path="work"
                    element={
                      <SpecialNotesPage
                        pageTitle={getPageTitle(TAB_ID.SPECIAL_NOTE, REPORT_TYPE.WORK)}
                        reportType={REPORT_TYPE.WORK}
                      />
                    }
                  />
                </Route>
                <Route path="/comments">
                  <Route
                    path="daily"
                    element={
                      <CommentsPage
                        pageTitle={getPageTitle(TAB_ID.COMMENT, REPORT_TYPE.DAILY)}
                        reportType={REPORT_TYPE.DAILY}
                      />
                    }
                  />
                  <Route
                    path="work"
                    element={
                      <CommentsPage
                        pageTitle={getPageTitle(TAB_ID.COMMENT, REPORT_TYPE.WORK)}
                        reportType={REPORT_TYPE.WORK}
                      />
                    }
                  />
                </Route>
                <Route
                  path="/workreport"
                  element={
                    <WorkReportPage
                      pageTitle={getPageTitle(TAB_ID.WORK_REPORT, REPORT_TYPE.WORK)}
                    />
                  }
                />
                <Route path="/settings">
                  <Route
                    path="printtemplate"
                    element={<PrintTemplatePage pageTitle="帳票登録" />}
                  />
                </Route>
              </>
            )}
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
