import { useContext, useEffect, useState } from "react";

import { Button, Col, Modal, Row } from "react-bootstrap";
import { Calendar, DateObject } from "react-multi-date-picker";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { useCommon } from "common/hooks/useCommon";
import { getDateString, getHolidayName } from "utils/commonTools";
import { daysInMonth } from "utils/DateTools";

import gregorian_ja from "./configs/gregorian_ja";

import PrimaryButton from "../atoms/PrimaryButton";
import { NameID } from "common/types/NameID";
import { ParkInfo } from "common/types/ParkInfo";
import { REPORT_TYPE, DailyMenuOrder, WorkMenuOrder } from "common/types/consts/Defines";
import { CancelButton } from "../atoms/CancelButton";

type Props = {
  show: boolean;
  initialValue: Date;
  onOK: (date: Date) => void;
  onCancel: () => void;
};

//=====================================
// カレンダー表示用のダイアログ
//=====================================
export const ILCalendarDlg = (props: Props) => {
  const { show, initialValue, onOK, onCancel } = props;

  const { selectedPark } = useContext(AuthContext);
  const { setErrorMessage } = useContext(StatusContext);

  const [date, setDate] = useState(initialValue);
  const [reportedDailyTabs, setReportedDailyTabs] = useState<NameID[] | null>(null);
  const [reportedWorkTabs, setReportedWorkTabs] = useState<NameID[] | null>(null);
  const [selectedDays, setSelectedDays] = useState<Date[] | undefined>(undefined);

  const { getReportDays, getReportedTabs } = useCommon();

  const SAVE_BY_APP = -1;

  //--------------------------------------
  // 日報登録済みの日付を取得し、カレンダーに表示する
  //--------------------------------------
  const loadReisteredDate = async (date: Date) => {
    if (selectedPark) {
      var resDaily = await getReportDays(selectedPark?.parkId, date, 0);
      var resWork = await getReportDays(selectedPark.parkId, date, 1);
      if (resDaily.succeeded && resWork.succeeded) {
        var days = Array.from(new Set([...resDaily.data!, ...resWork.data!]));
        setSelectedDays(days);
        await updateReportedTabs(selectedPark, days, date);
      } else {
        setErrorMessage(resDaily.msg);
      }
    }
  };

  const onMonthOrYearChange = (e: DateObject) => {
    const newMonthDate = e.toDate();
    // 元々選択されていた日付を選択 11/7 → 10/7 → 9/7 となる
    const newDay = Math.min(date.getDate(), daysInMonth(newMonthDate));
    const newDate = new Date(newMonthDate.getFullYear(), newMonthDate.getMonth(), newDay);
    setDate(newDate);
    loadReisteredDate(newMonthDate);
  };

  //--------------------------------------
  // 日付変更のイベント
  //--------------------------------------
  const onChange = (val: DateObject | DateObject[] | null) => {
    const dataObject = val as DateObject;
    if (!dataObject || !selectedPark) return;

    const dt = dataObject.toDate();
    dt.setHours(0, 0, 0);
    setDate(dt);
    const f = async () => updateReportedTabs(selectedPark, selectedDays!, dt);
    f();
  };

  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDay() === date2.getDay()
    );
  };

  //--------------------------------------
  // その日の登録済みタブ一覧を取得
  //--------------------------------------
  const updateReportedTabs = async (park: ParkInfo, days: Date[], date: Date) => {
    if (!days || !days.find((x) => isSameDay(x, date))) {
      setReportedDailyTabs(null);
      setReportedWorkTabs(null);
      return;
    }

    const dailyTabs = await execGetReportedTabs(
      park.parkId,
      REPORT_TYPE.DAILY,
      park.dailyTabs,
      date
    );
    setReportedDailyTabs(dailyTabs ?? []);

    const workTabs = await execGetReportedTabs(park.parkId, REPORT_TYPE.WORK, park.workTabs, date);
    setReportedWorkTabs(workTabs ?? []);
  };

  const execGetReportedTabs = async (
    parkId: number,
    reportType: REPORT_TYPE,
    tabs: NameID[],
    date: Date
  ) => {
    const res = await getReportedTabs(parkId, reportType, date);
    if (!res.succeeded) {
      setReportedDailyTabs(null);
      setErrorMessage(res.msg);
      return;
    }
    const newTabs = res.data!.map((tabId) => {
      switch (tabId) {
        case SAVE_BY_APP:
          return { id: tabId, name: "アプリ" };
      }
      const tab = tabs.find((tab) => tab.id === tabId);
      return { id: tabId, name: tab?.name ?? "不明なタブ" };
    });
    return newTabs;
  };
  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    const f = async () => {
      await loadReisteredDate(initialValue);
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>日付の選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Calendar
              locale={gregorian_ja}
              value={date}
              onYearChange={onMonthOrYearChange}
              onMonthChange={onMonthOrYearChange}
              onChange={(e) => onChange(e)}
              mapDays={({ date }) => {
                let className = "";
                if (getHolidayName(date.toDate())) {
                  className = "highlight highlight-red";
                }

                if (
                  selectedDays?.find(
                    (x) => x.getMonth() === date.monthIndex && x.getDate() === date.day
                  )
                ) {
                  className += " bg-success";
                }

                return { className };
              }}
            />
            <Button
              variant="outline-success"
              className="mt-2"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                const today = new Date();
                setDate(today);
                if (today.getMonth() !== date.getMonth()) loadReisteredDate(today);
              }}
            >
              今日
            </Button>
          </Col>
          <Col>
            <div>{`${getDateString(date)}`}</div>
            <div className="small">{getHolidayName(date) ?? "平日"}</div>
            <hr />
            <div style={{ height: "220px", overflow: "auto" }}>
              <fieldset>
                <legend className="h6 mb-2">日報登録状況</legend>
                {reportedDailyTabs &&
                  DailyMenuOrder.map((menu) => {
                    const tab = reportedDailyTabs.find((x) => x.id === menu);
                    return tab ? (
                      <div
                        key={`daytab_${tab.id}_${tab.name}`}
                        className="px-0 py-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <i className="fas fa-check pe-2" />
                        {tab.name}
                      </div>
                    ) : (
                      <span key={Math.random()}></span>
                    );
                  })}
              </fieldset>
              <hr />
              <fieldset>
                <legend className="h6 mb-2">作業日報登録状況</legend>
                {reportedWorkTabs &&
                  WorkMenuOrder.map((menu) => {
                    const tab = reportedWorkTabs.find((x) => x.id === menu);
                    return tab ? (
                      <div
                        key={`worktab_${tab.id}_${tab.name}`}
                        className="px-0 py-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <i className="fas fa-check pe-2" />
                        {tab.name}
                      </div>
                    ) : (
                      <span key={Math.random()}></span>
                    );
                  })}
              </fieldset>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          className="mx-2"
          onClick={() => {
            onOK(date);
          }}
          title="決定"
        />
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
