import { IncidentDetail, IncidentTotal } from "../types/IncidentReport";
import { INCIDENT_TYPE } from "../types/IncidetnTypes";

export const useIncidentData = () => {
  const createEmptyTotals = (): IncidentTotal[] => {
    return [
      { nameID: { id: INCIDENT_TYPE.POLICE, name: "警察" }, value: 0 },
      { nameID: { id: INCIDENT_TYPE.FIRE_DEP, name: "消防" }, value: 0 },
      { nameID: { id: INCIDENT_TYPE.AMBULANCE, name: "救急" }, value: 0 },
      { nameID: { id: INCIDENT_TYPE.NO_CALL, name: "通報なし" }, value: 0 },
      { nameID: { id: INCIDENT_TYPE.TOTAL, name: "合計" }, value: 0 },
    ];
  };

  const calcSum = (details: IncidentDetail[], icdtType: number) => {
    return details
      .map<number>((x) => (x.reportTypes.find((y) => y.id === icdtType) ? 1 : 0))
      .reduce((prev, current) => prev + current, 0);
  };

  const createEmptyData = (): IncidentDetail => {
    return {
      refNo: "",
      reportTypes: [{ id: INCIDENT_TYPE.NO_CALL, name: "通報なし" }],
      description: "",
      completed: false,
    };
  };

  //--------------------------------------
  // 合計の再計算を行う
  //--------------------------------------
  const updateTotals = (totals: IncidentTotal[], details: IncidentDetail[]) => {
    var total = totals.find((x) => x.nameID.id === INCIDENT_TYPE.TOTAL)!;
    total.value = 0;

    totals.forEach((icdt) => {
      if (icdt.nameID.id === INCIDENT_TYPE.TOTAL) return;
      icdt.value = details.filter(
        (x) => x.reportTypes.find((y) => y.id === icdt.nameID.id) !== undefined
      ).length;
      total.value += icdt.value;
    });

    // totals.forEach((icdt) => {
    //   if (icdt.nameID.id !== INCIDENT_TYPE.TOTAL) {
    //     icdt.value += calcSum(details, icdt.nameID.id);
    //     total.value += icdt.value;
    //   }
    // });

    return [...totals];
  };

  return { createEmptyTotals, createEmptyData, updateTotals };
};
