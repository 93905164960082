import { useContext } from "react";

import StatusContext from "common/store/StatusContext";

import { useCustomReport } from "../hooks/useCustomReport";
import { PrintPanelTemplate } from "./PrintPanelTemplate";
import { SelectDayRangeControl } from "./organisms/SelectDayRangeControl";

export const PrintCustomControl = (props: { templateId: number; title: string }) => {
  const { getCustomReport } = useCustomReport();
  const { isLoading, setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const printRequested = async (startDate: Date, endDate: Date) => {
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage(`帳票"${props.title}"を作成中...`);

    const { succeeded, msg } = await getCustomReport(
      startDate,
      endDate,
      props.templateId,
      props.title
    );
    setIsLoading(false);

    if (!succeeded) {
      setErrorMessage(msg);
    }
  };

  return (
    <PrintPanelTemplate>
      <SelectDayRangeControl disabled={isLoading} printRequested={printRequested} />
    </PrintPanelTemplate>
  );
};
