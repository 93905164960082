import React, { LegacyRef, forwardRef } from "react";

type Props = { className?: string; children: React.ReactNode };
export const DrawerOverflow = forwardRef((props: Props, ref: LegacyRef<HTMLDivElement>) => {
  const { className, ...other } = props;
  const classNameActual = `react-bootstrap-drawer-overflow ${className || ""}`.trim();

  return (
    <div ref={ref} {...other} className={classNameActual}>
      {props.children}
    </div>
  );
});
