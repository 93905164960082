import { useContext, useEffect } from "react";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { BasicTemplate } from "common/components/templates/BasicTemplate";

import { SelectParkPanel } from "./components/SelectParkPanel";
import { usePark } from "./hooks/usePark";
import { ParkInfo } from "common/types/ParkInfo";
import { useTabEditing } from "common/hooks/useTabEditing";
import { genEditKey } from "utils/commonTools";

function SelectParkPage() {
  const { parks, selectPark } = useContext(AuthContext);
  const { getTabs } = usePark();
  const { setIsLoading, setLoadingMessage, setErrorMessage, editingTab, setEditingTab } =
    useContext(StatusContext);
  const { endEditing } = useTabEditing();

  const parkSelected = async (park: ParkInfo) => {
    // 利用可能なタブを取得
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("公園のタブ情報取得中");

    const { succeeded, msg, data } = await getTabs(park.parkId);
    setIsLoading(false);
    setErrorMessage("");
    if (!succeeded) {
      setErrorMessage(msg);
      return;
    }

    park.dailyTabs = data?.dailyReport ?? [];
    park.workTabs = data?.workReport ?? [];

    // 公園を設定することでページが自動で遷移
    selectPark(park);
  };

  const f = async () => {
    if (editingTab) {
      await endEditing(editingTab.parkId, editingTab.reportType, editingTab.tabId, editingTab.key);
      setEditingTab(undefined);
    }
  };
  f();

  useEffect(() => {
    document.title = "InfoLand2：公園選択";
  }, []);

  return (
    <BasicTemplate>
      <SelectParkPanel parkSelected={parkSelected} parks={parks} />
    </BasicTemplate>
  );
}

export default SelectParkPage;
