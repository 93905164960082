import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { PageTop } from "common/components/organisms/PageTop";
import ConfirmationDlg from "common/components/molecules/ConfirmationDlg";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { createDocumentTitle } from "utils/commonTools";

import { PayFacilityData, PayFacilitySummary, PayFacilityUsage } from "./types/PayFacilityData";
import { PayFacilitiesMeta, PayFacilityMeta } from "./types/PayFacilityMeta";
import { usePayFacility } from "./hooks/usePayFacility";
import { usePayFacilityData } from "./hooks/usePayFacilityData";
import { PayFacilitySummaryTable } from "./components/PayFacilitySummaryTable";
import { PayFacilityDetailTable } from "./components/PayFacilityDetailTable";
import { EditPayFacilityDlg, TargetFacility } from "./components/EditPayFacilityDlg";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

let isInitializing = false;

//=====================================
// 有料施設ページ
//=====================================
const PayFacilityPage = (props: { pageTitle: string }) => {
  const pageTitle = props.pageTitle;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);
  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const [metaData, setMetaData] = useState<PayFacilitiesMeta | null>(null);
  const [summaries, setSummaries] = useState<PayFacilitySummary[] | null>(null);
  const [details, setDetails] = useState<PayFacilityData[]>([]);

  const { loadMetaData, getReportData, saveReportData } = usePayFacility();
  const {
    createEmptyData,
    createEmptyPayFacilityUsage,
    updateMeta,
    createEmptySummaries,
    updateSummaries,
  } = usePayFacilityData();

  const [editTarget, setEditTarget] = useState<TargetFacility | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<TargetFacility | null>(null);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<PayFacilitiesMeta | null> => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: PayFacilitiesMeta) => {
    if (!selectedPark) return;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );

    // 削除された施設情報があれば復元する
    const updatedMeta = updateMeta(baseData, data?.details);
    setMetaData(updatedMeta);

    // サマリーは再集計する
    const summaries = createEmptySummaries(updatedMeta.facilities);
    if (data?.details) {
      const updatedSummaries = updateSummaries(summaries, data.details);
      if (updatedSummaries) {
        setSummaries(updatedSummaries);
      } else {
        setSummaries(summaries);
      }
    } else {
      setSummaries(summaries);
    }

    if (data?.details) {
      // 保存済みデータあり
      // 保存後にメタデータが変更された場合、詳細がない施設が存在してしまうケースへの対応
      // ex. テニスコートA、テニスコートB で保存後、テニスコートCが追加されるケース
      const newFacilities = baseData.facilities.filter(
        (x) => !data.details.find((y) => y.facility.id === x.facility.id)
      );
      setDetails([...data.details, ...newFacilities?.map((x) => createEmptyData(x.facility))]);
    } else {
      // 新規追加
      setDetails(baseData?.facilities.map((x) => createEmptyData(x.facility)));
    }
  };

  //--------------------------------------
  // データの追加
  //--------------------------------------
  const addDataRequested = (meta: PayFacilityMeta) => {
    const newData = createEmptyPayFacilityUsage();
    setEditTarget({
      index: -1,
      metaData: meta,
      data: newData,
      paymentMethods: metaData?.paymentMethods,
      currentDate,
    });
  };

  //--------------------------------------
  // データの編集
  //--------------------------------------
  const editDataRequested = (data: PayFacilityUsage, meta: PayFacilityMeta, index: number) => {
    setEditTarget({
      index,
      metaData: meta,
      data,
      paymentMethods: metaData?.paymentMethods,
      currentDate,
    });
  };

  //--------------------------------------
  // 編集対象の施設データを取り出す処理を共通化
  //--------------------------------------
  const findCurrentFacility = (target: TargetFacility) =>
    details.find((x) => x.facility.id === target?.metaData.facility.id);

  //--------------------------------------
  // データの追加・編集の完了
  //--------------------------------------
  const addOrEditCompleted = (data: PayFacilityUsage) => {
    if (!editTarget) return;

    var facility = findCurrentFacility(editTarget);
    if (!facility) return;

    if (!facility.usages) facility.usages = [];

    if (editTarget.index < 0) {
      // 追加
      facility.usages?.push(data);
    } else {
      // 編集
      facility.usages[editTarget.index] = data;
    }
    setDetails(details);
    setSummaries(updateSummaries(summaries!, details!) ?? null);
    setEditTarget(null);
  };

  //--------------------------------------
  // 削除の実行
  //--------------------------------------
  const confirmDeleteOK = async () => {
    if (!deleteTarget) return;

    var facility = findCurrentFacility(deleteTarget);
    if (!facility) return;

    facility.usages = facility.usages!.filter((x) => x !== deleteTarget?.data);
    setDetails(details);
    setSummaries(updateSummaries(summaries!, details!) ?? null);
    setDeleteTarget(null);
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !summaries) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(selectedPark.parkId, currentDate, {
      summaries,
      details,
    });

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadData = async () => {
    if (metaData) await loadReportData(metaData);
  };

  return (
    <DashboardTemplate>
      {deleteTarget && (
        <ConfirmationDlg
          show={deleteTarget !== null}
          title="データの削除"
          messages={["選択したデータを削除しても良いですか"]}
          onOk={confirmDeleteOK}
          onCancel={async () => {
            setDeleteTarget(null);
          }}
        />
      )}

      {editTarget && (
        <EditPayFacilityDlg
          target={editTarget}
          onOK={addOrEditCompleted}
          onCancel={() => setEditTarget(null)}
        />
      )}

      <FormContainer size="max">
        {!selectedPark && <div> 公園が選択されていません </div>}
        <PageTop
          title={pageTitle}
          reportType={REPORT_TYPE.DAILY}
          tabId={TAB_ID.PAY_FACILITY}
          lastUpdated={lastUpdated}
          onEditStart={async () => await reloadData()}
          onSave={!readOnly ? onSave : undefined}
          onCancel={async () => await reloadData()}
        />
        <BaseForm className="p-3">
          <PayFacilitySummaryTable Summaries={summaries} MetaData={metaData} Details={details} />
          <Tabs>
            {metaData &&
              metaData.facilities.map((meta) => (
                <Tab
                  eventKey={meta.facility.id}
                  title={meta.facility.name}
                  key={`faciltab_${meta.facility.id}`}
                  tabClassName={`${meta.isDeleted && "deleted-tab"}`}
                >
                  <PayFacilityDetailTable
                    Usages={
                      details?.find((x) => x.facility.id === meta.facility.id)?.usages || null
                    }
                    Meta={meta}
                    readonly={meta.isDeleted || !isEditing}
                    addDataRequested={() => addDataRequested(meta)}
                    editDataRequested={(data, index) => editDataRequested(data, meta, index)}
                    deleteDataRequested={(data, index) =>
                      setDeleteTarget({ metaData: meta, index, data, currentDate })
                    }
                  />
                </Tab>
              ))}
          </Tabs>
        </BaseForm>
      </FormContainer>
    </DashboardTemplate>
  );
};

export default PayFacilityPage;
